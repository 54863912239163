import React, { Component } from "react";

export class Privacy extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <div className="">
        <div className="min-h-screen h-full bg-center bg-no-repeat bg-cover bg-errorHeader px-[8vw] py-[8rem] flex flex-col justify-center gap-8 text-left">
          <div className="oito:w-1/2">
            <h1 className="text-h3 font-GilroyBold seis:text-h1">{pt ? "Termos e políticas" : "Terms & Policies"}</h1>
            <p>{pt ? "Esta Política de Privacidade é efetiva a partir de 15/1/2024. A Vision D reserva-se o direito de modificar esta política a qualquer momento. Encorajamos a revisão periódica desta página para as informações mais recentes sobre as nossas práticas de privacidade."
              : "This Privacy Policy is effective as of 15/1/2024. Vision D reserves the right to modify this policy at any ​time. We encourage you to periodically review this page for the latest information on our privacy ​practices."}</p>
          </div>
        </div>
        <div className="min-h-screen h-full flex flex-col px-[8vw] bg-[var(--blackBlue)] py-[4rem] text-left">
          {
            pt ?
              <p>
                <b>Bem-vindo à Vision D !</b><br /><br />
                Localizado em www.visiond.pt, a Vision D e seus subdomínios oferecem serviços de desenvolvimento de software e consultoria. Ao acessar ou usar o nosso Website, concorda com os seguintes termos e condições ("Termos de Uso") e Política de Privacidade. Estes documentos regem o seu uso do nosso Website e dos serviços associados, conteúdo e funcionalidade.<br /><br />
                <hr className="mb-8"/>
                <strong>Política de Privacidade</strong><br /><br />
                <strong>A Sua Privacidade:</strong><br />
                A Vision D respeita a sua privacidade e compromete-se a protegê-la através do cumprimento desta política. Esta seção informa-o sobre as nossas políticas relativas à recolha, utilização e divulgação de dados pessoais quando utiliza o nosso Serviço e as escolhas associadas a esses dados.<br /><br />
                <strong>Recolha e Uso de Informações:</strong><br />
                Recolhemos vários tipos de informações para diversos fins para fornecer e melhorar o nosso Serviço para si. Isso inclui informações que nos fornece diretamente, informações recolhidas automaticamente sobre o seu uso do nosso Website e informações que obtemos de terceiros.<br /><br />
                <strong>Uso dos Dados:</strong><br />
                Os dados recolhidos são usados para manter o Website, para notificá-lo sobre alterações ao nosso Serviço, para permitir que participe em funcionalidades interativas do nosso Serviço quando decide fazê-lo, para fornecer suporte ao cliente, para recolher análises ou informações valiosas de modo a que possamos melhorar o nosso Serviço, para monitorizar a utilização do nosso Serviço, para detetar, prevenir e resolver problemas técnicos, para fornecer-lhe notícias, ofertas especiais e informações gerais sobre outros bens, serviços e eventos que oferecemos.<br /><br />
                <strong>Partilha de Dados:</strong><br />
                Podemos partilhar as suas informações com terceiros quando nos autoriza explicitamente a fazê-lo. Adicionalmente, podemos usar prestadores de serviços de terceiros para atender vários aspectos do Website, como o Google Analytics e fornecedores de serviços de email para newsletters.<br /><br />
                <strong>Cookies:</strong><br />
                O nosso Website usa cookies para melhorar a sua experiência. Tem a opção de recusar estes cookies e saber quando um cookie está sendo enviado para o seu computador. Se optar por recusar os nossos cookies, poderá não conseguir usar algumas partes do nosso Serviço.<br /><br />
                <hr className="mb-8"/>
                <strong>Termos de Uso</strong><br /><br />
                <strong>Acesso e Uso do Website:</strong><br />
                O Website destina-se a fins informativos. É responsável pelo seu uso do Website e do seu conteúdo. A Empresa possui todos os direitos de propriedade intelectual no conteúdo do Website, e concede-lhe uma licença limitada para usar o conteúdo do Website para fins pessoais e não comerciais.<br /><br />
                <strong>Usos Proibidos:</strong><br />
                É proibido usar o Website de uma maneira que danifique, desabilite, sobrecarregue ou prejudique o Website ou interfira com o uso do Website por qualquer outra parte. Usar o Website para fins não autorizados, incluindo, mas não se limitando a, mineração de dados, raspagem ou métodos similares de recolha e extração de dados, é expressamente proibido sem consentimento prévio por escrito da Empresa.<br /><br />
                <strong>Direitos de Propriedade Intelectual:</strong><br />
                O conteúdo, disposição e layout do Website, incluindo, mas não se limitando a, marcas comerciais, fotos e logotipos, são propriedade exclusiva da Vision D, protegidos por leis de direitos autorais e de propriedade intelectual.<br /><br />
                <strong>Contribuições do Utilizador:</strong><br />
                Os utilizadores podem contribuir com conteúdo para o Website em várias formas, desde que tais contribuições não infrinjam quaisquer direitos de terceiros ou leis. A Empresa reserva-se o direito de remover qualquer conteúdo contribuído pelo utilizador a seu critério.<br /><br />
                <strong>Limitações de Responsabilidade:</strong><br />
                A Empresa não será responsável por quaisquer danos resultantes do seu uso do Website ou de qualquer informação, conteúdo, materiais incluídos no ou disponibilizados através do Website.<br /><br />
                <strong>Alterações aos Termos e Política de Privacidade:</strong><br />
                A Vision D reserva-se o direito de modificar ou atualizar estes Termos e Política de Privacidade a qualquer momento sem aviso prévio. O seu uso contínuo do Website após quaisquer alterações indica a sua aceitação dos novos Termos e Política de Privacidade.<br /><br />
                <strong>Contacte-nos:</strong><br />
                Para questões sobre estes Termos ou Política de Privacidade, por favor contacte-nos em <a href="mailto:geral@visiond.pt" className="hover:underline">geral@visiond.pt</a><br /><br />
              </p>
              :
              <p>
                <b>Welcome to Vision D !</b><br /><br />
                Located at www.visiond.pt, Vision D and its subdomains offer software development and consultancy services. By accessing or using our Website, you agree to the following terms and conditions ("Terms of Use") and Privacy Policy. These documents govern your use of our Website and its associated services, content, and functionality.<br /><br />
                <hr className="mb-8"/>
                <strong>Privacy Policy</strong><br /><br />
                <strong>Your Privacy:</strong><br />
                Vision D respects your privacy and is committed to protecting it through our compliance with this policy. This section informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.<br /><br />
                <strong>Information Collection and Use:</strong><br />
                We collect several different types of information for various purposes to provide and improve our Service to you. This includes information you provide directly to us, information collected automatically about your use of our Website, and information we obtain from third parties.<br /><br />
                <strong>Use of Data:</strong><br />
                Data collected is used to maintain the Website, to notify you about changes to our Service, to allow you to participate in interactive features of our Service when you choose to do so, to provide customer support, to gather analysis or valuable information so that we can improve our Service, to monitor the usage of our Service, to detect, prevent and address technical issues, to provide you with news, special offers and general information about other goods, services, and events which we offer.<br /><br />
                <strong>Sharing of Data:</strong><br />
                We may share your information with third parties when you explicitly authorize us to do so. Additionally, we may use third-party service providers to service various aspects of the Website, such as Google Analytics and email service providers for newsletters.<br /><br />
                <strong>Cookies:</strong><br />
                Our Website uses cookies to enhance your experience. You have the option to refuse these cookies and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.<br /><br />
                <hr className="mb-8"/>
                <strong>Terms of Use</strong><br /><br />
                <strong>Access and Use of the Website:</strong><br />
                The Website is intended for informational purposes. You are responsible for your use of the Website and its content. The Company owns all intellectual property rights in the Website content, and you are granted a limited license to use the Website content for personal, non-commercial purposes.<br /><br />
                <strong>Prohibited Uses:</strong><br />
                You are prohibited from using the Website in a way that damages, disables, overburdens, or impairs the Website or interferes with any other party's use of the Website. Using the Website for unauthorized purposes, including but not limited to, data mining, scraping, or similar data gathering and extraction methods, is expressly prohibited without prior written consent from the Company.<br /><br />
                <strong>Intellectual Property Rights:</strong><br />
                The content, arrangement, and layout of the Website, including but not limited to, the trademarks, photos, and logos, are the sole and exclusive property of Vision D, protected by copyright and intellectual property rights laws.<br /><br />
                <strong>User Contributions:</strong><br />
                Users may contribute content to the Website in various forms, provided such contributions do not infringe on any third-party rights or laws. The Company reserves the right to remove any user-contributed content at its discretion.<br /><br />
                <strong>Limitations of Liability:</strong><br />
                The Company will not be liable for any damages arising from your use of the Website or from any information, content, materials included on or otherwise made available to you through the Website.<br /><br />
                <strong>Changes to Terms and Privacy Policy:</strong><br />
                Vision D reserves the right to modify or update these Terms and Privacy Policy at any time without notice. Your continued use of the Website after any changes indicates your acceptance of the new Terms and Privacy Policy.<br /><br />
                <strong>Contact Us:</strong><br />
                For questions about these Terms or Privacy Policy, please contact us at <a href="mailto:geral@visiond.pt" className="hover:underline">geral@visiond.pt</a><br /><br />
              </p>
          }
        </div>

      </div>
    );
  }
}

export default Privacy;