import React, { Component } from "react";
import "./card.css";

export class Card extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <section id="portfolio-card" className="relative flex justify-center items-center h-[50vh] mt-0">
        <div className="relative flex justify-center items-center h-[70%] w-[90%] bg-portfolioCard bg-center bg-cover bg-no-repeat rounded-[25px]">
          <button onClick={() => window.location.replace('/budget')} className="relative border-white border-[2px] p-[10px] px-[15px]  bg-[#4649be] text-p">{pt ? "Descobrir mais" : "Discover more"}</button>
        </div>
      </section>
    );
  }
}

export default Card;
