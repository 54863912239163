import React from 'react';
import Icon1 from "../../content/images/icons-logos/partners1.png";
import Icon2 from "../../content/images/icons-logos/partners2.png";
import Icon3 from "../../content/images/icons-logos/partners3.png";
import AirparkLogo from "../../content/images/icons-logos/airpark.png";
import SogimonteLogo from "../../content/images/icons-logos/logo-sogimonte.png";

export default function Partners() {
    const partnerName = new URLSearchParams(window.location.search).get('partnerName') || 'PartnerName';
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

    return (
        <div className='min-h-screen'>
            <div className='flex flex-col items-center justify-center h-screen gap-20 bg-center bg-no-repeat bg-cover bg-loginHeader'>
                <h1 className='font-GilroyBold text-h2 '>VISION D & {partnerName}</h1>
                <h2 className='text-h2'>{pt ? "Parceria 2024" : "2024 Partnership"}</h2>
                <button id='budget-header-button' onClick={() => window.location.replace(`/partner?partnerName=${partnerName}#info`)} className='relative'>↓</button>
            </div>
            <div id="info" className='w-full min-h-screen py-[7rem] px-[8vw] bg-[var(--blackBlue)] flex flex-col gap-20'>
                <div className='flex flex-col items-center w-full gap-10 font-bold text-center '>
                    <p className='nove:w-1/4'>{pt ? "Este site demonstra o potencial do que podemos alcançar juntos." : "This site showcases the potential of what we can achieve together."}</p>
                    <p className='text-[1.8rem] nove:text-h3 font-GilroyBold'>{pt ? "CONVITE" : "INVITATION"}</p>
                    <p className='nove:w-1/4'>{pt ? "PARA EXPLORAR OPORTUNIDADES DE PARCERIA QUE PROMETEM" : "TO EXPLORE PARTNERSHIP OPPORTUNITIES THAT PROMISE"}</p>
                    <ul className='text-left list-disc font-GilroyBold'>
                        <li>{pt ? "CRESCIMENTO MÚTUO" : "MUTUAL GROWTH"}</li>
                        <li>{pt ? "INOVAÇÃO" : "INNOVATION"}</li>
                        <li>{pt ? "SUCESSO" : "SUCCESS"}</li>
                    </ul>
                </div>
                <div className='flex flex-col gap-20'>
                    <h3 className='font-GilroyBold text-[1.8rem] nove:text-h3'>{pt ? "SOBRE A VISION D" : "ABOUT VISION D"}</h3>
                    <div className='flex flex-col items-center gap-8 miledois:items-center miledois:justify-between miledois:flex-row'>
                        <div className="relative z-[10]">
                            <div className='relative p-4 nove:p-8 w-[16rem] h-[15rem] nove:w-[20rem] nove:h-[15rem] bg-white rounded-[25px] text-black border-2 border-black flex nove:justify-start justify-center items-center flex-col gap-6 z-[20]'>
                                <p className='uppercase font-GilroyBold text-[1.2rem]'>{pt ? "CENTRAL DE INOVAÇÃO" : "INNOVATION ​POWERHOUSE"}</p>
                                <p className='font-bold'>{pt ? "Transformando ideias ambiciosas em sucessos líderes de mercado." : "Transforming ambitious ​ideas into market-leading ​successes."}</p>
                            </div>
                            <div className='absolute bottom-[-1rem] right-[-1rem] nove:right-[-1rem] p-6 w-[14rem] h-[14rem] nove:w-[18rem] nove:h-[13rem] bg-[var(--pink)] rounded-[20px] z-[5]'></div>
                        </div>
                        <div className="relative z-[10]">
                            <div className='relative p-4 nove:p-8 w-[16rem] h-[15rem] nove:w-[20rem] nove:h-[15rem] bg-white rounded-[25px] text-black border-2 border-black flex nove:justify-start justify-center items-center flex-col gap-6 z-[20]'>
                                <p className='uppercase font-GilroyBold text-[1.2rem]'>{pt ? "TECNOLOGIA NO NÚCLEO" : "TECH AT THE ​CORE"}</p>
                                <p className='font-bold'>{pt ? "Apps, modelos de IA, IoT, Webapps, ERPs, Back-Offices, Web3" : "Apps, AI models, IoT, ​Webapps, ERPs, Back-​Offices, Web3"}</p>
                            </div>
                            <div className='absolute bottom-[-1rem] right-[-1rem] nove:left-[50%] nove:translate-x-[-50%] p-6 w-[14rem] h-[14rem] nove:w-[18rem] nove:h-[13rem] bg-[var(--pink)] rounded-[20px] z-[5]'></div>
                        </div>
                        <div className="relative z-[10]">
                            <div className='relative p-4 nove:p-8 w-[16rem] h-[15rem] nove:w-[20rem] nove:h-[15rem] bg-white rounded-[25px] text-black border-2 border-black flex nove:justify-start justify-center items-center flex-col gap-6 z-[20]'>
                                <p className='uppercase font-GilroyBold text-[1.2rem]'>{pt ? "MISSÃO DE TRANSFORMAÇÃO" : "MISSION OF ​TRANSFORMATION"}</p>
                                <p className='font-bold'>{pt ? "Melhorar quaisquer capacidades web ou de software para otimização total." : "Better any web or software ​capabilities to full ​optimization."}</p>
                            </div>
                            <div className='absolute bottom-[-1rem] right-[-1rem] nove:left-[-1rem] p-6 w-[14rem] h-[14rem] nove:w-[18rem] nove:h-[13rem] bg-[var(--pink)] rounded-[20px] z-[5]'></div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-20 mt-20 nove:px-[8vw]'>
                    <h3 className='font-GilroyBold text-[1.8rem] nove:text-h3'>{pt ? "PARCERIA PROPOSTA" : "PROPOSED PARTNERSHIP"}</h3>
                    <div className='flex flex-col gap-8 text-left font-GilroyBold'>
                        <h4 className='italic text-[1.8rem] nove:text-h3'>{pt ? "OBJETIVOS" : "OBJECTIVES"}</h4>
                        <p className='text-[1.8rem] nove:text-h3'>{pt ? "A nossa parceria visa:" : "Our partnership aims to:"}</p>
                        <ul className='pl-4 list-decimal nove:pl-12 text-[1.2rem] cinco:text-h4'>
                            <li>{pt ? "Aproveitar as forças tecnológicas e de mercado mútuas." : "Leverage mutual technological and market strengths."}</li>
                            <li>{pt ? "Co-desenvolver soluções inovadoras para atender às necessidades do mercado emergente." : "Co-develop innovative solutions to address emerging market ​needs."}</li>
                            <li>{pt ? "Expandir o nosso alcance de mercado, beneficiando das redes e experiência de cada um." : "Expand our market reach, benefiting from each other's ​networks and expertise."}</li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col gap-20 mt-20 nove:px-[8vw]'>
                    <h3 className='font-GilroyBold text-[1.8rem] nove:text-h3'>{pt ? "BENEFÍCIOS DO PARCEIRO" : "BENEFITS FOR THE PARTNER"}</h3>
                    <div className='flex flex-col items-center gap-8 nove:items-center nove:justify-between nove:flex-row'>
                        <div className='p-8 w-[18rem] nove:w-[20rem] flex flex-col gap-6 items-center justify-center'>
                            <img src={Icon1} alt="icon" className='w-[5rem]' />
                            <p>{pt ? "Acesso a recursos tecnológicos de ponta e expertise. Acesso ao painel de parceiros." : "Access to cutting-edge technological ​resources and expertise, partner ​dashboard access."}</p>
                        </div>
                        <div className='p-8 w-[18rem] nove:w-[20rem] flex flex-col gap-6 items-center justify-center'>
                            <img src={Icon2} alt="icon" className='w-[5rem]' />
                            <p>{pt ? "Presença de mercado aprimorada através de plataformas e redes compartilhadas." : "Enhanced market presence through shared platforms and networks."}</p>
                        </div>
                        <div className='p-8 w-[18rem] nove:w-[20rem] flex flex-col gap-6 items-center justify-center'>
                            <img src={Icon3} alt="icon" className='w-[5rem]' />
                            <p>{pt ? "Joint ventures que aproveitam forças, levando a inovação acelerada e crescimento." : "Joint ventures that leverage strengths, leading to accelerated innovation and growth."}</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-20 mt-20 nove:px-[8vw]'>
                    <h3 className='uppercase font-GilroyBold text-[1.8rem] nove:text-h3'>{pt ? "Âmbito da Parceria" : "Scope of Partnership"}</h3>
                    <div className='flex flex-col gap-8 text-left font-GilroyBold'>
                        <h4 className='italic text-[1.8rem] nove:text-h3'>{pt ? "A nossa colaboração abrangerá:" : "Our collaboration will encompass:"}</h4>
                        <ul className='pl-4 list-disc nove:pl-12 text-[1.2rem] cinco:text-h4'>
                            <li>{pt ? "Desenvolvimento conjunto de soluções tecnológicas inovadoras." : "Joint development of innovative technological solutions."}</li>
                            <li>{pt ? "Shared market research efforts to identify new opportunities." : "Esforços de pesquisa de mercado compartilhados para identificar novas oportunidades."}</li>
                            <li>{pt ? "Estratégias de promoção cruzada e expansão de mercado." : "Cross-promotion and market expansion strategies."}</li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col gap-20 mt-20 nove:px-[8vw]'>
                    <h3 className='uppercase font-GilroyBold text-[1.8rem] nove:text-h3'>{pt ? "CRONOGRAMA E PLANEAMENTO" : "TIMELINE & PLANNING"}</h3>
                    <div className='flex flex-col gap-8 text-left font-GilroyBold'>
                        <ul className='pl-4 list-decimal nove:pl-12 text-[1.2rem] cinco:text-h4'>
                            <li>{pt ? "Acesso ao Painel: Monitore o seu dinheiro trazido e atividade, agende reuniões, documentos compartilhados." : "Access Dashboard: Monitor your brought money and monitor activity, schedule meeting, shared documents."}</li>
                            <li>{pt ? "Desenvolvimento de Estratégia: Automatizado no painel, reunião para aprovar e começar." : "Strategy Development: Automated in the dashboard, meeting to approve and start."}</li>
                            <li>{pt ? "Lançamento de Iniciativas Conjuntas: Dentro de 1 a 6 meses, seguindo uma fase de planeamento de projeto detalhada." : "Launch of Joint Initiatives: Within 1 month to 6 months, following a detailed project planning phase."}</li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col gap-20 mt-20'>
                    <h3 className='text-left uppercase font-GilroyBold text-[1.8rem] nove:text-h3'>{pt ? "HISTÓRIAS DE SUCESSO" : "SUCCESS STORIES"}</h3>
                    <div className='flex flex-col items-center gap-10 nove:justify-between nove:flex-row'>
                        <p className='text-left'>{pt ? "O nosso portfólio fala muito da nossa capacidade de transformar visão em realidade. Desde revolucionar os processos de recrutamento de RH até pioneirismo em blockchain na educação, os nossos projetos não só estabeleceram novos padrões da indústria, mas também impulsionaram os nossos parceiros a novas alturas." : "Our portfolio speaks volumes of our capability to turn vision into reality. From ​revolutionizing HR recruitment processes to pioneering blockchain in ​education, our projects have not only set new industry standards but have ​also propelled our partners to new heights."}</p>
                        <div className='flex justify-center w-full nove:w-auto'>
                            <button onClick={() => window.location.href = "/portfolio"} className='px-8 py-4 text-black transition-all duration-300 bg-white rounded-full font-GilroyBold whitespace-nowrap hover:brightness-75'>{pt ? "O NOSSO PORTFÓLIO" : "OUR PORTFOLIO"}</button>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-20 mt-20'>
                    <h3 className='text-left uppercase font-GilroyBold text-[1.8rem] nove:text-h3'>{pt ? "TESTEMUNHOS" : "TESTIMONIALS"}</h3>
                    <div className='grid grid-cols-1 gap-10 nove:grid-cols-2'>
                        <div className='flex flex-col gap-6 text-left'>
                            <div className='h-[5rem]'>
                                <img src={AirparkLogo} alt="logo" className='w-[12rem]' />
                            </div>
                            <p className='font-GilroyBold'>"{pt ? "A NOSSA PARCERIA COM A VISION D TRANSFORMOU A NOSSA ABORDAGEM AOS DESAFIOS DE MERCADO, IMPULSIONANDO UM CRESCIMENTO SEM PRECEDENTES." : "OUR PARTNERSHIP WITH VISION D TRANSFORMED ​OUR APPROACH TO MARKET CHALLENGES, ​DRIVING UNPRECEDENTED GROWTH."}"</p>
                            <p className='italic'>- AIRPARK MOBILITY</p>
                        </div>
                        <div className='flex flex-col gap-6 text-left'>
                            <div className='h-[5rem]'>
                                <img src={SogimonteLogo} alt="logo" className='w-[12rem]' />
                            </div>
                            <p className='font-GilroyBold'>"{pt ? "A VISION D AJUDOU AS NOSSAS NECESSIDADES DIGITAIS E TROUXE-NOS ORIGINALIDADE E CRIATIVIDADE." : "VISION D HELPED OUR DIGITAL NEEDS AND ​BROUGHT US ORIGINALITY AND ​CREATIVITY."}"</p>
                            <p className='italic'>- SOGIMONTE CONSULTING</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='h-[50vh] bg-white px-[8vw] py-[3rem] flex flex-col justify-between items-center text-black text-center'>
                <h3 className='uppercase font-GilroyBold text-[1.6rem] nove:text-[1.8rem] nove:text-h3'>{pt ? "VAMOS COMEÇAR A TRABALHAR" : "LET’S START WORKING"}</h3>
                <p className='font-bold'>{pt ? "CONVIDAMO-LO A JUNTAR-SE A NÓS NESTA JORNADA DE INOVAÇÃO E CRESCIMENTO." : "WE INVITE YOU TO JOIN US IN THIS JOURNEY OF INNOVATION AND GROWTH."}</p>
                <div className='flex justify-center w-full'>
                    <button onClick={() => {window.localStorage.setItem('partnerForm', 'form'); window.location.href = "/new-partner"}} className='px-20 py-2 text-white transition-all duration-300 bg-[var(--pink)] rounded-full font-GilroyBold whitespace-nowrap hover:brightness-75'>{pt ? "COMEÇAR" : "START"}</button>
                </div>
            </div>
        </div>
    );
}

