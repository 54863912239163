import React from 'react';
import { Helmet } from "react-helmet-async";
import {Header, Web, App, Software, Card} from './index'; 

export default function Services() {
  return (
    <div id="portfolio-all">
      <Helmet>
        <title>Portfolio</title>
        <meta name="description" content="A sample of what we have developed" />
        <link rel='canonical' href='/portfolio' />
      </Helmet>
      <Header/>
      <Web/>
      <App/>
      <div className='bg-portfolioSoftware bg-cover bg-no-repeat'>
        <Software/>
        <Card/>
      </div>
    </div>
    
  );  
}
