import { Helmet } from "react-helmet-async";

import React, {useEffect} from 'react';
import {Header, Ai, Web, Blockchain, App, Ux, Startups} from './index'; 
import "./services/services.css"

export default function Services() {

  useEffect(() => {
    let service = window.localStorage.getItem('service');

    if (service === null) return
    
    let path = '/services#services-' + service;

    window.location.replace(path);
    window.localStorage.removeItem('service');
  }, [])

  return (
    <div id="services-all" className='overflow-x-hidden'>
      <Helmet>
        <title>Services</title>
        <meta name="description" content="Everything that we create, code and launch" />
        <link rel='canonical' href='/services' />
      </Helmet>
      <Header/>
      <Web/>
      <App/>
      <Ux/>
      <Blockchain/>
      <Ai/>
      <Startups/>
    </div>
    
  );  
}
