import React from "react";
import "./about.css";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

window.addEventListener('scroll', reveal);

let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

function About() {
  return (
    <div id="home-about-all" className="home-section1-sections flex justify-start items-start bg-homeAbout bg-no-repeat bg-cover  w-3/5">
      <div className="fade-black-img absolute opacity-0 h-full w-full rounded-[35px]"></div>
      <div id="home-about-left" className="relative flex flex-col justify-start items-start text-left w-1/2 pl-14 pt-14 overflow-hidden">
          <h2 id="home-about-title" className="reveal text-h2 mb-10">{pt ? "Sobre nós" : "About us"}</h2>
          {pt ? 
          <h3 className="reveal">
            A Vision D oferece serviços de TI de alta qualidade para empresas de todos os tamanhos por meio de uma equipe de profissionais qualificados. As nossas soluções de TI incluem desenvolvimento de sites e aplicações mobile, propostas de design e marketing digital.
            <br /><br />
            A Vision D também possui uma plataforma exclusiva para talentos de TI. Esta plataforma ajuda as empresas a encontrar e contratar profissionais qualificados de forma rápida e fácil. Especialistas em TI podem mostrar suas habilidades e experiência para atender aos requisitos das empresas.
          </h3>
          :
          <h3 className="reveal">
            Vision D provides high-quality IT services to businesses of all sizes through a team of skilled professionals. Their IT solutions include website and app development, design proposals, and digital marketing.
            <br /><br />
            Vision D's also has an unique dashboard for IT talents. This dashboard helps companies quickly and easily find and hire skilled professionals. IT experts can showcase their skills and experience to match businesses' requirements.
          </h3>
          }
      </div>
    </div>
  );
}

export default About;
