import React from 'react';

export default function LatestPost({ postData }: { postData: any }) {

    function selectPost(title:string) {
        localStorage.setItem('selectedPostTitle', title);
        console.log(title)
        console.log(localStorage.getItem('selectedPostTitle'))
        window.location.href = `/post`;
    }

    function PostCard() {
        return (
            <div onClick={() => selectPost(postData?.titulo)} className='cursor-pointer w-full mil:w-[60%] h-[24rem] sete:h-[12rem] bg-white rounded-[25px] flex sete:gap-8 sete:flex-row flex-col'>
                <img src={postData?.imagem} alt="blogimage" className='w-full sete:h-full sete:w-[30%] h-[40%] rounded-[25px] object-cover' />
                <div className='px-8 sete:px-0 py-4 flex flex-col justify-between text-left text-black pr-4 h-full'>
                    <h5 className=' text-[1.2rem] oito:text-[1.5rem] font-bold'>{postData?.titulo}</h5>
                    <h5 className=' text-[1rem] oito:text-[1.2rem]'>{postData?.descricao}</h5>
                </div>
            </div>
        )
    }

    return (
        <div id='latest' className='bg-[var(--darkBlue)] px-[8vw] py-[5rem]'>
            <h1 className='text-[2rem] text-left'><b>Latest</b> Post</h1>
            <div className="mt-[8rem] w-full flex justify-center">
                {
                    !postData ?
                        <div className='h-[15rem] flex items-center'>
                            <p className='w-full text-center'>There is no post published</p>
                        </div>
                        :
                        <PostCard />
                }
            </div>
        </div>
    );
}

