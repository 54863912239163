import React from "react";

import BlockchainIcon from "../../../content/images/icons-logos/web3-logo.png";
import LogoBackyard from "../../../content/images/icons-logos/logo-backyard.png";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

function goToForm() {
  localStorage.setItem("form", "Blockchain");
  window.location.replace('/form');
}

window.addEventListener('scroll', reveal);

function Blockchain() {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  return (
    <section id="services-blockchain" className="relative flex h-screen bg-center bg-no-repeat bg-cover service-all bg-servicesBlockchain">
      <div className="absolute w-full h-full fade-black-img"></div>
      <div className="relative w-full h-full reveal">
        <div className="relative flex h-full p-24 services-container">
          <div className="relative flex items-center justify-start w-1/2 h-full services-container-left">
            <div className="relative flex flex-col justify-around h-full services-container-line mt-14">
            <div className="relative services-container-line-point code "></div>
              <div className="relative services-container-line-point app "></div>
              <div className="relative services-container-line-point ux "></div>
              <div className="relative services-container-line-point web3 bigger "></div>
              <div className="relative services-container-line-point ai "></div>
              <div className="relative services-container-line-point loaning"></div>
            </div>
            <div className="relative flex flex-col items-start justify-start h-full services-container-info p-14">
              <div className="relative flex flex-col justify-between h-full">
                <div id="services-Blockchain-container-info">
                  <div className="services-container-info-img">
                    <img src={BlockchainIcon} alt="startup" className="w-16" />
                  </div> 
                  <div className="mt-6 text-left services-container-info-title">
                    <h1 className="text-h3">Blockchain</h1>
                  </div>
                  <div className="mt-6 text-left services-container-info-text">
                  {pt ?
                  <p>
                    Experiencia os benefícios dos sistemas descentralizados, desde a segurança aprimorada até as transações transparentes. <br /><br /> As integrações de blockchain irão simplificar as tuas operações e elevar o teu negócio ao próximo nível. Junte-te a nós na revolução da web3.</p>
                  :              
                  <p>
                    Experience the benefits of decentralized systems, from enhanced security to transparent transactions. <br /><br /> Our blockchain integrations will streamline your operations and elevate your business to the next level. Join us on the forefront of web3 technology today.
                  </p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative hidden cinco:flex flex-col items-start justify-end w-1/2 h-full pb-40 services-container-right">
            <div className="mb-10 services-container-portofolio-title">
              <h1 className="relative text-left text-white text-h3">{pt ? "Portfolio" : "Portfólio"}</h1>
            </div>
            <div className="seis:max-w-[25rem] max-w-[18rem] overflow-x-auto my-scrollbar relative flex">
              <div className="relative flex justify-start mb-2 services-container-portofolio-cards-container">
                <div onClick={() => window.location.replace('https://www.backyard-rr.com')} className="services-container-portofolio-card relative bg-[#6BA6E1] bg-center bg-cover flex items-center justify-center hover: cursor-pointer">
                    <img src={LogoBackyard} alt="backyard logo" className="w-14"/>
                </div>
              </div>
            </div>
          </div>
          <div onClick={() => goToForm()} className="absolute service-contact-btn bottom-16 right-16"><span className="mr-2">{pt ? "interessado" : "interested"}</span><span>→</span></div>
        </div>
      </div>
    </section>
  );
}

export default Blockchain;