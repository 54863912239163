import React from 'react';

import Background from '../../content/images/login/login-header.png'

export default function Header() {
    return (
        <div className='relative min-h-screen min-h-[100dvh] flex justify-center items-center flex-col '>
            <img src={Background} alt="background" className='absolute top-0 left-0 w-full h-full flex justify-center items-center object-center object-cover z-[-1]' />
            <h1 className='text-[2rem]'><b>Vision D</b> - Blog</h1>
            <div className="mt-10 text-h5">
                <div id="services-header-button" onClick={() => window.location.replace("/blog#latest")} className="relative flex items-center justify-center">↓</div>
            </div>
        </div>
    );
}

