import React from 'react';

export default function Partners() {
    return (
        <div className='min-h-screen'>
            <div className='flex flex-col items-center justify-center h-screen gap-20'>

            </div>
        </div>
    );
}

