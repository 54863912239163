import React from 'react';
import AppRoutes from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { Navbar, Footer } from './containers/index';

function App() {
  return (
    <div className='relative text-center text-white font-GilroyLight'>
      <BrowserRouter>
        {window.location.pathname !== "/links" && <Navbar />}
        <AppRoutes />
        {window.location.pathname !== "/links" && <Footer />}
      </BrowserRouter >
    </div >
  );
}

export default App;
