import React from "react";
import CodeImage from "../../../../content/images/icons-logos/logo-code.png";
import BtnVideo from './btnVideo';

const Code = () => {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
        <div className="section2-selected-service w-2/5 flex flex-col justify-end items-center pr-24">
            <div className="flex flex-col justify-end items-start mb-24">
                <img src={CodeImage} alt="startup" className=" mb-10"/>
                <h2 className="text-h2 mb-6 text-left">{pt ? "Desenvolvimento web" : "Web development"}</h2>
                {pt ?
                    <p className="text-left mb-10">As nossas equipas especialistas em programação e design criam plataformas online e websites que envolvem os utilizadores e geram resultados para o seu negócio.</p>
                    :
                    <p className="text-left mb-10">Our expert developers create websites and web apps that engage users and drive results for your business.</p>
                }
                <div className="flex w-full justify-end items-end">
                    <div onClick={() => {window.localStorage.setItem('service', 'code'); window.location.replace('/services')}} className="section2-selected-service-button text-white w-48 h-12 border rounded-full bg-homeServicesBtn1 bg-no-repeat bg-cover bg-center">
                        <div className="section2-service-video code absolute flex w-full h-full">
                            <BtnVideo/>
                        </div>                        
                        <p className="z-50">{pt ? "saber mais" : "see more"} &ensp; → </p>
                    </div>                
                </div>
            </div>
        </div> 
    )
}

export default Code;
