import React, { useState }  from 'react';
// import {MdArrowForwardIos} from 'react-icons/md';
// import {MdArrowBackIosNew} from 'react-icons/md';

import "./sectionAbout.css";
import About from "./about";
import Create from "./create";
import Code from "./code";
import Launch from "./launch";



function Selectedbutton( { section = 0 } ) {
    if (section === 0) {
        return <About/>;
    } else if (section === 1) {
        return <Create/>;
    } else if (section === 2) {
        return <Code/>;
    } else if (section === 3) {
        return <Launch/>;
    } else {
        return null;
    }
}

function SectionAbout() {
    const[section, setSection] = useState<number>(0);

    void setSection;
    
    // let create = document.getElementById("home-sectionAbout-create");
    // let code = document.getElementById("home-sectionAbout-code");
    // let launch = document.getElementById("home-sectionAbout-launch");

    // function btnLeftFunction() {
    //     if (section === 0) {
    //         return null;
    //     }
    //     create?.classList.remove("active");
    //     code?.classList.remove("active");
    //     launch?.classList.remove("active");
    //     setSection(section - 1);
    //     if (section === 2) {
    //         create?.classList.add("active");
    //     } else if (section === 3) {
    //         code?.classList.add("active");
    //     } else if (section === 4) {
    //         launch?.classList.add("active");
    //     }
    // }

    // function btnRightFunction() {
    //     create?.classList.remove("active");
    //     code?.classList.remove("active");
    //     launch?.classList.remove("active");
    //     if (section !== 3) {
    //         setSection(section + 1);
    //     }
    //     if (section === 0) {
    //         create?.classList.add("active");
    //     } else if (section === 1) {
    //         code?.classList.add("active");
    //     } else if (section === 2) {
    //         launch?.classList.add("active");
    //     }
    // }

    // function setSectionFunction(sectionNumber = 0) {
    //     if (section === 0 && window.screen.width <= 800) {
    //         return null;
    //     }
    //     create?.classList.remove("active");
    //     code?.classList.remove("active");
    //     launch?.classList.remove("active");
    //     setSection(sectionNumber);
    // }

    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
   
    return (
        <section id="home-sectionAbout" className="relative flex justify-center items-end h-screen top-20">
            <Selectedbutton section={section}/>
            <div id="home-sectionAbout-slogan-buttons" className="reveal relative flex justify-between items-end w-3/5 z-40 pb-10">
                <div id="home-sectionAbout-slogan" className='text-h3 text-left'>
                    <p>{pt ? "A sua visão," : "Developing"}</p>
                    <p>{pt ? "a nossa implementação." : "your Digital Dream."}</p>
                </div>
                {/* <div id="home-sectionAbout-buttons" className="relative flex justify-end items-end text-p">
                    <div className="home-sectionAbout-lateral-btn left relative h-[9rem] flex justify-center items-center"><MdArrowBackIosNew onClick={() => btnLeftFunction()} className="hover: cursor-pointer"/></div>
                    <div className='relative flex justify-center'>
                        <button onClick={() => setSectionFunction(1)} id="home-sectionAbout-create" className="home-sectionAbout-btn">create</button>
                        <button onClick={() => setSectionFunction(2)} id="home-sectionAbout-code" className="home-sectionAbout-btn">code</button>
                        <button onClick={() => setSectionFunction(3)} id="home-sectionAbout-launch" className="home-sectionAbout-btn">launch</button>
                    </div>
                    <div className="home-sectionAbout-lateral-btn right relative h-[9rem] flex justify-center items-center"><MdArrowForwardIos onClick={() => btnRightFunction()} className="hover: cursor-pointer"/></div>
                </div> */}
            </div>
        </section>
    );
}

export default SectionAbout;