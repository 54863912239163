import React from 'react';

function btnVideo() {
    return (
        <>
            <div className="circle relative left-0 w-full"></div>
            <div className="circle absolute w-2 h-2 left-[10rem]"></div>
            <div className="circle absolute w-6 h-6 left-[8.5rem] top-[1.5rem]"></div>
            <div className="circle absolute w-3 h-3 left-[10.5rem] top-[.5rem]"></div>
            <div className="circle absolute w-6 h-6 left-[9.5rem] top-[1.5rem]"></div>
            <div className="circle absolute w-4 h-4 left-[11.3rem] top-[.7rem]"></div>
            <div className="circle absolute w-6 h-6 left-[12rem] top-[1rem]"></div>
            <div className="circle absolute w-3 h-3 left-[13.5rem] top-[.2rem]"></div>
            <div className="circle absolute w-6 h-6 left-[14.5rem] top-[1rem]"></div>
            <div className="circle absolute w-3 h-3 left-[15.5rem] top-[1.5rem]"></div>
            <div className="circle absolute w-2 h-2 left-[11.2rem] top-[2.2rem]"></div>
            <div className="circle absolute w-3 h-3 left-[12rem] top-[.2rem]"></div>
            <div className="circle absolute w-2 h-2 left-[14rem] top-[1rem]"></div>
            <div className="circle absolute w-3 h-3 left-[13.5rem] top-[1.5rem]"></div>
            <div className="circle absolute w-2 h-2 left-[12rem] top-[2.3rem]"></div>
            <div className="circle absolute w-3 h-3 left-[15rem] top-[.5rem]"></div>
            <div className="circle absolute w-1 h-1 left-[14.5rem] top-[.2rem]"></div>
            <div className="circle absolute w-1 h-1 left-[13rem] top-[.5rem]"></div>
            <div className="circle absolute w-1 h-1 left-[14.5rem] top-[.2rem]"></div>
            <div className="circle absolute w-3 h-3 left-[16.2rem] top-[.7rem]"></div>
            <div className="circle absolute w-2 h-2 left-[16.8rem] top-[1.5rem]"></div>
            <div className="circle absolute w-1 h-1 left-[17.5rem] top-[.8rem]"></div>
        </>
    )
}

export default btnVideo;