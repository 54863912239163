import React from "react";
import StartupsImage from "../../../../content/images/icons-logos/logo-startups.png";
import BtnVideo from './btnVideo';

const StartUps = () => {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
        <div className="section2-selected-service w-1/3 flex flex-col justify-end items-center">
            <div className="flex flex-col justify-end items-start mb-24">
                <img src={StartupsImage} alt="startup" className=" mb-10"/>
                <h2 className="text-h2 mb-6">Startups</h2>
                <p className="text-left">{pt ? "O nosso serviço de incubação ajuda startups em estágio inicial a crescerem, fornecendo mentoria, recursos e oportunidades de financiamento para dar vida às ideias." : "Our startup incubator service supports early-stage startups in their growth by providing them with mentorship, resources, and funding opportunities to bring ideas to life."}</p>
                <div className="flex w-full justify-end items-end mt-10">
                    <div onClick={() => {window.localStorage.setItem('service', 'loaning'); window.location.replace('/services')}} className="section2-selected-service-button text-white w-48 h-12 border rounded-full bg-homeServicesBtn1 bg-no-repeat bg-cover bg-center">
                        <div className="section2-service-video loaning absolute flex w-full h-full">
                            <BtnVideo/>
                        </div>
                        <p className="z-50">{pt ? "saber mais" : "see more"} &ensp; → </p>
                    </div>    
                </div>
            </div>
        </div> 
    )
}

export default StartUps;
