import React from "react";
import BlockchainImage from "../../../../content/images/icons-logos/web3-logo.png";
import BtnVideo from './btnVideo';

const Blockchain = () => {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
        <div className="section2-selected-service w-1/3 flex flex-col justify-end items-center">
            <div className="flex flex-col justify-end items-start mb-24">
                <img src={BlockchainImage} alt="startup" className="w-14 mb-10"/>
                <h2 className="text-h2 mb-6">Blockchain</h2>
                <p className="text-left mb-10">{pt ? "Melhore o seu negócio com nossas soluções seguras e eficientes de blockchain. Junte-se ao movimento web3 hoje." : "Revolutionize your business with our secure and efficient blockchain solutions. Join the web3 movement today."}</p>
                <div className="flex w-full justify-end items-end">
                    <div onClick={() => {window.localStorage.setItem('service', 'blockchain'); window.location.replace('/services')}} className="section2-selected-service-button text-white w-48 h-12 border rounded-full bg-homeServicesBtn1 bg-no-repeat bg-cover bg-center">
                        <div className="section2-service-video web3 absolute flex w-full h-full">
                            <BtnVideo/>
                        </div>
                        <p className="z-50">{pt ? "saber mais" : "see more"} &ensp; → </p>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default Blockchain;
