import React, { useEffect, useState } from 'react';
import { db, storage } from '../../firebase.js';
import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { FaCheck } from "react-icons/fa";

export default function Partners() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

    let inputStyle = "border-white border-2 bg-[transparent] px-2 py-1 rounded-[8px] w-full outline-[var(--purple)]";

    const [formData, setFormData] = useState<any>({
        companyName: '',
        tradeName: '',
        businessSector: '',
        fullAddress: '',
        taxId: '',
        website: '',
        generalPhone: '',
        generalEmail: '',
        signatoryName: '',
        positionWithPowers: '',
        contactName: '',
        contactEmail: '',
        contactLandline: '',
        contactMobile: '',
        contactPosition: '',
        logo: null,
        presentation: null
    });

    const [terms, setTerms] = useState(false);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        let form = window.localStorage.getItem('partnerForm');
    
        if (form === null) return;
        
        let path = `/new-partner#${form}`;
    
        window.location.replace(path);
        window.localStorage.removeItem('partnerForm');
      }, [])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const name = target.name;
        const file = target.files ? target.files[0] : null;
        if (!file)
            return;
        if (file.size > 1048576) {
            alert('File size must be less than 1MB');
            return;
        }
        if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'application/pdf') {
            alert('Invalid file type. Only png, jpg and pdf are allowed.');
            return;
        }
        setFormData({
            ...formData,
            [name]: file
        });
    };

    const handleInputChange = (event: any) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    async function submitForm() {
        if (!terms) {
            alert(pt ? "Por favor aceite os termos e condições" : "Please accept the terms and conditions");
            return;
        }
        for (const key in formData) {
            if (key === 'logo' || key === 'presentation' || key === 'contactLandline' || key === 'fullAddress') continue;
            if (formData[key] === '') {
                alert(pt ? "Por favor preencha todos os campos" : "Please fill all fields");
                setClicked(false);
                return;
            }
        }
        setClicked(true);
        // Save image
        if (formData.logo) {
            try {
                const storageRef = ref(storage, `/docsPartners/${formData.logo.name}`);
                const snapshot = await uploadBytes(storageRef, formData.logo);
                const coverURL = await getDownloadURL(snapshot.ref);
                formData.logo = coverURL;
            } catch (error) {
                console.error('Error uploading image:', error);
                alert(pt ? "Ocorreu um erro ao salvar a imagem. Por favor tente novamente" : "An error occurred while saving the image. Please try again");
                setClicked(false);
                return;
            }
        }
        // Save pdf
        if (formData.presentation) {
            try {
                const storageRef = ref(storage, `/docsPartners/${formData.presentation.name}`);
                const snapshot = await uploadBytes(storageRef, formData.presentation);
                const coverURL = await getDownloadURL(snapshot.ref);
                formData.presentation = coverURL;
            } catch (error) {
                console.error('Error uploading pdf:', error);
                alert(pt ? "Ocorreu um erro ao salvar o pdf. Por favor tente novamente" : "An error occurred while saving the pdf. Please try again");
                setClicked(false);
                return;
            }
        }
        const newPartner = {
            ...formData,
            type: "Partner",
            lead: true,
            name: formData.companyName,
            email: formData.contactEmail,
            responsavel: formData.contactName,
            role: formData.contactPosition,
            contacto: formData.contactMobile,
            websiteLink: formData.website,
            creationDate: new Date().toISOString(),
            partnerType: formData.businessSector,
            active: true,
            profileImg: formData?.logo || null,
            createdBy: 'website'
        }
        try {
            await setDoc(doc(db, 'websiteForms', formData.contactEmail), newPartner);
            alert(pt ? "Formulário enviado com sucesso" : "Form submitted successfully");
        } catch (err) {
            console.log(err);
            alert(pt ? "Ocorreu um erro ao enviar o formulário. Por favor tente novamente" : "An error occurred while submitting the form. Please try again");
            setClicked(false);
            return;
        }
        // TODO: Send email to partner and to admin
        setFormData({
            companyName: '',
            tradeName: '',
            businessSector: '',
            fullAddress: '',
            taxId: '',
            website: '',
            generalPhone: '',
            generalEmail: '',
            signatoryName: '',
            positionWithPowers: '',
            contactName: '',
            contactEmail: '',
            contactLandline: '',
            contactMobile: '',
            contactPosition: '',
            logo: null,
            presentation: null
        });
        setTerms(false);
        setClicked(false);
    }

    return (
        <div className='min-h-screen'>
            <div className='bg-center bg-no-repeat bg-cover bg-budgetHeader'>
                <div className='flex flex-col items-center justify-center h-screen w-full bg-[rgba(0,0,0,.2)] gap-4'>
                    <h1 className='relative font-bold text-[1.6rem] cinco:text-[2rem] nove:text-h2'>{pt ? "Vamos trabalhar juntos" : "LET'S START WORKING"}</h1>
                    <h2 className='px-[8vw] quatro:text-center text-left nove:w-1/2 text-p'>{pt ? "Queremos convida-lo a juntar-se a nós nesta jornada de inovação e crescimento. Por favor, complete as informações para receber um e-mail com as informações da conta do painel, para gestão da parceria e termos acordados" : "We invite you to join us in this journey of innovation and growth. Please complete the info bellow to receive an e-mail with dashboard account info, partnership manager and agreed terms"}</h2>
                    <button id='budget-header-button' onClick={() => window.location.replace("/new-partner#form")} className='relative mt-5'>↓</button>
                </div>
            </div>
            <div id='form' className='min-h-screen py-[8rem] px-[8vw] bg-[var(--blackBlue)]'>
                <h2 className='text-left text-h3 mb-14'>{pt ? "Dados de novo parceiro" : "New partner information"}</h2>
                <div className='flex flex-col gap-10'>
                    <p className='text-left text-[var(--purple)] font-bold'>{pt ? "INFORMAÇÃO GERAL" : "GENERAL INFORMATION"}</p>
                    <div className='grid grid-cols-1 gap-8 nove:grid-cols-2'>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='companyName'>{pt ? "Nome da Empresa" : "Company Name"}</label>
                            <input className={inputStyle} type='text' id='companyName' name='companyName' value={formData.companyName} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='tradeName'>{pt ? "Nome Comercial" : "Commercial Name"}</label>
                            <input className={inputStyle} type='text' id='tradeName' name='tradeName' value={formData.tradeName} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='businessSector'>{pt ? "Ramo de Atividade" : "Business Sector"}</label>
                            <input className={inputStyle} type='text' id='businessSector' name='businessSector' value={formData.businessSector} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='taxId'>{pt ? "NIF" : "Tax Identification Number"}</label>
                            <input className={inputStyle} type='text' id='taxId' name='taxId' value={formData.taxId} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='website'>{pt ? "Página Web" : "Website"}</label>
                            <input className={inputStyle} type='url' id='website' name='website' value={formData.website} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='generalPhone'>{pt ? "Contacto telefónico geral" : "General Phone Contact"}</label>
                            <input className={inputStyle} type='tel' id='generalPhone' name='generalPhone' value={formData.generalPhone} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='generalEmail'>{pt ? "E-mail geral" : "General Email"}</label>
                            <input className={inputStyle} type='email' id='generalEmail' name='generalEmail' value={formData.generalEmail} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='signatoryName'>{pt ? "Nome da pessoa que vai assinar o acordo de parceria" : "Name of the person signing the partnership agreement"}</label>
                            <input className={inputStyle} type='text' id='signatoryName' name='signatoryName' value={formData.signatoryName} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='positionWithPowers'>{pt ? "Cargo com poderes para o ato" : "Position with powers to act"}</label>
                            <input className={inputStyle} type='text' id='positionWithPowers' name='positionWithPowers' value={formData.positionWithPowers} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left' htmlFor='fullAddress'>{pt ? "Morada Completa" : "Full Address"}</label>
                            <input className={inputStyle} type='text' id='fullAddress' name='fullAddress' value={formData.fullAddress} onChange={handleInputChange} />
                        </div>
                    </div>
                    <p className='font-bold quatro:text-[1.2rem] mt-8'>{pt ? "Dados de contacto (pessoa responsável pelo acompanhamento da parceria)" : "Contact Details (person responsible for the partnership management)"}</p>
                    <p className='text-left text-[var(--purple)] font-bold'>{pt ? "RESPONSÁVEL" : "RESPONSIBLE"}</p>
                    <div className='grid grid-cols-1 gap-8 nove:grid-cols-2'>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='contactName'>{pt ? "Nome" : "Name"}</label>
                            <input className={inputStyle} type='text' id='contactName' name='contactName' value={formData.contactName} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='contactEmail'>{pt ? "E-mail" : "Email"}</label>
                            <input className={inputStyle} type='email' id='contactEmail' name='contactEmail' value={formData.contactEmail} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='contactMobile'>{pt ? "Telemóvel" : "Mobile Phone"}</label>
                            <input className={inputStyle} type='tel' id='contactMobile' name='contactMobile' value={formData.contactMobile} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left mandatory' htmlFor='contactPosition'>{pt ? "Cargo" : "Position"}</label>
                            <input className={inputStyle} type='text' id='contactPosition' name='contactPosition' value={formData.contactPosition} onChange={handleInputChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left' htmlFor='contactLandline'>{pt ? "Telefone fixo" : "Landline Phone"}</label>
                            <input className={inputStyle} type='tel' id='contactLandline' name='contactLandline' value={formData.contactLandline} onChange={handleInputChange} />
                        </div>
                    </div>
                    <p className='text-left text-[var(--purple)] font-bold'>{pt ? "DOCUMENTOS" : "DOCUMENTS"}</p>
                    <div className='grid grid-cols-1 gap-8 nove:grid-cols-2'>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left' htmlFor='logo'>{pt ? "Logotipo da empresa" : "Company's logo"} (png/jpg)</label>
                            <input className={inputStyle} type='file' id='logo' name='logo' onChange={handleFileChange} />
                        </div>
                        <div className='flex items-center gap-8'>
                            <label className='w-[20rem] text-left' htmlFor='presentation'>{pt ? "Apresentação da empresa" : "Company's presentation"} (pdf)</label>
                            <input className={inputStyle} type='file' id='presentation' name='presentation' onChange={handleFileChange} />
                        </div>
                    </div>
                    <div className='flex flex-col items-center w-full gap-8 mt-8'>
                        <p className='font-bold text-center nove:w-1/2'>{pt ? "Receberá um e-mail com informações da conta da dashboard, para gestão da parceria e termos acordados." : "You will receive an e-mail with dashboard account info, partnership manager, and agreed terms."}</p>
                        <p className='text-center'>{pt ? "Nota: Para qualquer questão, contacte" : "Note: For any questions, contact"} <a href="mailto:parcerias@visiond.pt" className='font-bold hover:underline'>parcerias@visiond.pt</a></p>
                        <button onClick={() => submitForm()} disabled={clicked} className='bg-[var(--pink)] text-white px-4 py-2 rounded-[8px] border-2 border-[var(--pink)] hover:bg-[transparent] transition-all duration-300 font-bold'>{pt ? "COMEÇAR" : "START"}</button>
                        <div className='flex items-center gap-4'>
                            <button onClick={() => setTerms(!terms)} className={`border-white rounded-[5px] w-6 h-6 border-2 flex justify-center items-center ${terms ? 'bg-[var(--pink)]' : ''}`}>{terms && <FaCheck />}</button>
                            {
                                pt ? 
                                <p>Li e aceito os <a href="/partner-terms" className='underline cursor-pointer hover:no-underline'>termos e condições</a></p>
                                :
                                <p>I have read and accept the <a href="/partner-terms" className='underline cursor-pointer hover:no-underline'>terms and conditions</a></p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

