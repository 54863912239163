import React from 'react';

import WebImage from "../../content/images/icons-logos/logo-code.png";
import AppImage from "../../content/images/icons-logos/logo-app.png";
import UxImage from "../../content/images/icons-logos/logo-ux.png";
import Web3Image from "../../content/images/icons-logos/web3-logo.png";

let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

function doForm(service:string) {
    localStorage.setItem("apply", service);
    window.location.replace('/apply-form');
}

function Services() {

    function Service({...props}) {
        let w = "w-8 mb-1";
        if (props.service === "App") {
            w = "w-6 mb-1";
        }

        return (
            <div  onClick={() => doForm(props.service) } className="apply-service-form-option relative flex flex-col justify-between items-center cursor-pointer px-5 py-7">
                <h2 className="relative w-full text-center">{props.text}</h2>
                <div className="form-service relative flex justify-center items-center text-black rounded-[25px] w-[13rem] h-[13rem] p-5 text-center cursor-pointer">
                    <img src={props.img} alt="img" className={w} />
                    <h3 className='text-p'>{props.service}</h3>
                </div>
            </div>
        )
    }

    return (
        <div id='area-form' className='relative bg-[#F1F1F1] min-h-[110vh] flex flex-col text-black text-left overflow-hidden justify-center px-[6vw] py-10'>
            <div id='form-slider-services-container' className='slider-ele relative'>
                <h2 className='relative w-full text-h2 mb-20'>{pt ? "Em que área gostavas de trabalhar?" : "In which area would you like to work?"}</h2>
                <div id='apply-form-services'>
                    <Service number={1} text={"Front-end development"} img={WebImage}  service="Frontend"/>
                    <Service number={2} text={"Back-end development"} img={Web3Image} service="Backend"/>
                    <Service number={3} text={"App development"} img={AppImage} service="App"/>
                    <Service number={4} text={"UX/UI Design"} img={UxImage} service="UX & UI"/>
                </div>
            </div>
        </div>
    );
}

export default Services;