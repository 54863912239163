import React from 'react';

import "./apply.css";

let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

function Header() {
  return (
    <main id="header-all" className='bg-budgetHeader bg-no-repeat bg-cover bg-center overflow-hidden h-screen flex flex-col justify-center items-center px-10'>
        {pt ? 
        <>
            <p className='relative text-h2'><b>Junta-te</b> à nossa equipa</p>
            <h1>Dá o próximo passo na tua carreira e faz a diferença connosco</h1>
        </>
        :
        <>
            <p className='relative text-h2'><b>Join</b> our team</p>
            <h1>Take the next step in your career and make a difference with us</h1>
        </>
        }
        <button id='budget-header-button' onClick={() => window.location.replace("/apply#area-form")} className='relative mt-5'>↓</button>
    </main>
    
  );
}

export default Header;