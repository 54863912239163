import React from "react";
import UxImage from "../../../../content/images/icons-logos/logo-ux.png";
import BtnVideo from './btnVideo';

const Ux = () => {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
        <div className="section2-selected-service w-1/3 flex flex-col justify-end items-center">
            <div className="flex flex-col justify-end items-start mb-24">
                <img src={UxImage} alt="startup" className="w-16 mb-10"/>
                <h2 className="text-h2 mb-6 text-left">{pt ? "Soluções de UX/UI" : "UX / UI Solutions"}</h2>
                <p className="text-left mb-10">{pt ? "A equipa de designers de UX e UI desenvolvem designs visualmente deslumbrantes, altamente intuitivos e fáceis de usar." : "UX and UI designers create visually stunning and user-friendly designs that elevate your brand and enhance user experience."}</p>
                <div className="flex w-full justify-end items-end">
                    <div onClick={() => {window.localStorage.setItem('service', 'ux'); window.location.replace('/services')}} className="section2-selected-service-button text-white w-48 h-12 border rounded-full bg-homeServicesBtn1 bg-no-repeat bg-cover bg-center">
                        <div className="section2-service-video ux absolute flex w-full h-full">
                            <BtnVideo/>
                        </div>                        
                        <p className="z-50">{pt ? "saber mais" : "see more"} &ensp; → </p>
                    </div>    
                </div>
            </div>
        </div> 
    )
}

export default Ux;
