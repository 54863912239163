import React from 'react'

import NewsLetter from './newsletter'
import './footer.css';

import logogithub from "../../content/images/icons-logos/logo-github.png";
import logoinstagram from "../../content/images/icons-logos/logo-instagram.png";
import logolinkedin from "../../content/images/icons-logos/logo-linkedin.png";

import logovision from "../../content/images/icons-logos/logo-visiond.png";

function CheckNewsLetter() {
  if (window.location.pathname === "/") {
    return (
      <div id='footer-newsletter' className='absolute flex w-4/5 p-10 px-10 bg-brown'>
        <NewsLetter/>
      </div>
    );
  } else {
    return null;
  }
}

function lightDownNewsLetter() {
  let newsLetter = document.getElementById('footer-newsletter');
  newsLetter?.classList.remove('light-up');
}

function lightUpNewsLetter() {
  let newsLetter = document.getElementById('footer-newsletter');
  newsLetter?.classList.add('light-up');
  setTimeout(lightDownNewsLetter, 1500);
}

function Footer() {
  let footerMenuClass = "mt-32";
  if (window.location.pathname === "/") {
    footerMenuClass = "mt-52";
  } 
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <footer id="footer-all" className='relative flex flex-col items-center text-left text-white'>
        <CheckNewsLetter />
        <div id="footer-menu" className={'relative flex justify-around w-4/5 ' + footerMenuClass }>
          <div className='relative flex flex-col items-center justify-start'>
            <p className='mb-3 cursor-default text-h4 hover:no-underline'>Worklab</p>
            <div className="flex flex-col items-center justify-center text-h5">
              <p onClick={() => window.location.replace('https://worklab.pt/signup')} className='cursor-pointer'>{pt ? "Para empresas" : "I'm hiring"}</p>
              <p onClick={() => window.location.replace('https://worklab.pt/signup')} className='cursor-pointer'>{pt ? "Para agents" : "Be an agent"}</p>
            </div>
          </div>
          <div className='relative flex flex-col items-center justify-start'>
            <p className='mb-3 cursor-default text-h4 hover:no-underline'>{pt ? "Para clientes" : "For clients"}</p>
            <div className="flex flex-col items-center justify-center text-h5">
              <a href="/services" className='cursor-pointer hover:underline'>{pt ? "Serviços" : "Services"}</a>
              <a href="/contact" className='cursor-pointer hover:underline'>{pt ? "Contacta-nos" : "Contact us"}</a>
              <a href="/budget" className='cursor-pointer hover:underline'>{pt ? "Orçamento" : "Budget"}</a>
            </div>
          </div>
          <div className='relative flex flex-col items-center justify-start'>
            <p className='mb-3 cursor-default text-h4 hover:no-underline'>{pt ? "Recursos" : "Resources"}</p>
            <div className="flex flex-col items-center justify-center text-h5">
              <a href="/blog" className='cursor-pointer hover:underline'>Blog</a>
              <p onClick={() => lightUpNewsLetter()} className='cursor-pointer hover:underline'>NewsLetter</p>
              <a href="/apply" className='cursor-pointer hover:underline'>Apply</a>
              <a href="/privacy"  className='cursor-pointer hover:underline'>{pt ? "Termos" : "Terms"}</a>
            </div>
          </div>
          <div className='relative flex flex-col items-center justify-start'>
            <p className='mb-3 cursor-default text-h4 hover:no-underline'>{pt ? "Contactos" : "Contacts"}</p>
            <div className="flex flex-col items-center justify-center contact-email text-h5">
              <p><a href="mailto: geral@visiond.pt">geral@visiond.pt</a></p>
              <p><a href="mailto: support@visiond.pt">support@visiond.pt</a></p>
            </div>
          </div>
        </div>
        <div id="footer-media" className='relative flex items-center justify-between w-4/5 mt-20 mb-6'>
          <div id="footer-logo" className='relative w-1/3'>
            <img onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} src={logovision} alt="Logo vision" className="cursor-pointer w-60 hover:" />
          </div>
          <div id='footer-media-social' className='relative flex items-center justify-around w-1/3'>
            <img src={logoinstagram} onClick={() => window.location.replace("https://instagram.com/visiondpt")} alt="Logo vision" className="w-10 h-10 footer-social-media brightness-0 invert" />
            <img src={logolinkedin} onClick={() => window.location.replace("https://www.linkedin.com/company/vision-d-developers/")} alt="Logo vision" className="w-10 h-10 footer-social-media brightness-0 invert" />
            <img src={logogithub} onClick={() => window.location.replace("https://github.com/visiond-community")} alt="Logo vision" className="w-10 h-10 footer-social-media brightness-0 invert" />
          </div>
          <div id='footer-media-title' className='relative flex items-center justify-end w-1/3'>
            <p className='text-h4'>- {pt ? "Segue as nossas redes" : "Follow us"}</p>
          </div>
        </div>
      </footer>
    )
}

export default Footer;
