import React from "react";

import LoaningIcon from "../../../content/images/icons-logos/logo-loaning.png";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

window.addEventListener('scroll', reveal);

function Loaning() {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  return (
    <section id="services-loaning" className="service-all relative flex h-screen bg-servicesLoaning bg-no-repeat bg-cover bg-center">
      <div className="fade-black-img absolute h-full w-full"></div>
      <div className="reveal">
        <div className="services-container relative flex h-full p-24">
          <div className="services-container-left relative h-full w-1/2 flex justify-start items-center">
            <div className="services-container-line relative flex flex-col h-full justify-around mt-14">
            <div className="services-container-line-point code relative "></div>
              <div className="services-container-line-point app relative "></div>
              <div className="services-container-line-point ux relative "></div>
              <div className="services-container-line-point web3 relative "></div>
              <div className="services-container-line-point ai relative "></div>
              <div className="services-container-line-point loaning bigger relative"></div>
            </div>
            <div className="services-container-info relative flex flex-col h-full justify-start items-start p-14">
              <div className="relative flex flex-col h-full justify-between">
                <div id="services-loaning-container-info">
                  <div className="services-container-info-img">
                    <img src={LoaningIcon} alt="startup" />
                  </div> 
                  <div className="services-container-info-title mt-6 text-left">
                    <h1 className="text-h3">{pt ? "Recrutamento" : "Loaning"}</h1>
                  </div>
                  <div className="services-container-info-text mt-6 text-left">
                  {pt ?
                  <p>
                    Simplifica o processo de recrutamento com nossa dashboard que combina a tua empresa com os melhores talentos de TI através de uma plataforma personalizável. <br /><br /> Encontra candidatos qualificados de forma rápida e eficiente, poupando tempo e dinheiro.</p>
                  :              
                  <p>
                    Simplify your hiring process with our platform that matches you with top IT talent through a customizable dashboard. <br /> <br /> Find skilled candidates quickly and efficiently, saving you time and money.
                  </p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-container-right relative flex flex-col h-full w-1/2 justify-start p-14 items-start">
            <div id="services-loaning-container-areas" className="relative w-4/5 ml-20">
                  <h1 className="relative text-h3 text-left mb-5">{pt ? "Alguns talentos" : "Some specialists"}</h1>
                  <div className="relative grid grid-cols-3 gap-x-5 gap-y-2 mb-5 w-full">
                    <h2>Web</h2>
                    <h2>App </h2>
                    <h2>Software</h2>
                    <h2>UX/UI</h2>
                    <h2>Clouds</h2>
                    <h2>Cyber</h2>
                  </div>
            </div>
            {/* <div className="services-container-portofolio relative relative flex flex-col h-1/2 mx-20">
              <div className="services-container-portofolio-title mb-10">
                <h1 className="relative text-h3 text-left">Partnerships</h1>
              </div>
              <div className="services-container-portofolio-cards-container relative flex justify-start">
                <div onClick={() => window.location.replace('/contact')} className="services-container-portofolio-card none relative flex items-center justify-center mr-5 hover: cursor-pointer">
                  <h2 className="text-h4">+</h2>
                </div>
              </div>
            </div> */}
          </div>
          <div onClick={() => window.location.replace('/dashboard')} className="service-contact-btn absolute bottom-16 right-16"><span className="mr-2">{pt ? "interessado" : "interested"}</span><span>→</span></div>
        </div>
      </div>
    </section>
  );
}

export default Loaning;