import React from 'react';
import { Helmet } from "react-helmet-async";

import "./apply.css";

import Header from "./header";
import Services from "./services";

function Budget() {
  return (
    <div id="budget-all">
      <Helmet>
        <title>Apply</title>
        <meta name="description" content="Join our team" />
        <link rel='canonical' href='/apply' />
      </Helmet>
      <Header/>
      <Services/>
    </div>
    
  );
}

export default Budget;