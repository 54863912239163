import React, { Component } from "react";
import "./app.css";

import Mockup1 from '../../../content/images/portfolio/app/mockup1.png';
import Mockup2 from '../../../content/images/portfolio/app/mockup2.png';
import SpodyLogo from '../../../content/images/portfolio/app/spody-logo.png';

export class App extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <section id="portfolio-app" className="relative min-h-screen flex justify-center bg-portfolioApp bg-cover bg-center bg-no-repeat px-[8vw]">
        <h2 className="absolute w-full text-right text-h2 top-16 right-[8vw]">APPs</h2>
        <div id="portfolio-app-mockups">
          <img src={Mockup1} alt="img" id="app-mockup1" className="app-mockups"/>
          <img src={Mockup2} alt="img" id="app-mockup2" className="app-mockups"/>
        </div>
        <div id="portfolio-app-info" className="relative w-[40%] flex flex-col text-h5 text-left pt-32 pl-8">
          <img id="portfolio-spody-logo" src={SpodyLogo} alt="img" className="w-[15rem] mb-10 reveal"/>
          {pt ?
            <p className="reveal">
              Concebida para inspirar e educar, a aplicação Spody é um testemunho da nossa capacidade de utilizar tecnologia de ponta para fins significativos. <br /><br /> Com a aplicação Spody demonstramos a nossa técnica e evidenciamos o nosso empenho em melhorar a forma como as pessoas aprendem e interagem num cenário digital moderno.
            </p>
            :              
            <p className="reveal">
              Designed to inspire and educate, the Spody app is a testament to our ability to harness cutting-edge technology for meaningful purposes. <br /><br /> We've demonstrated our technical expertise and also shown our dedication to enhancing the way people learn and interact in a modern digital landscape. 
            </p>
          }
        </div>
      </section>
    );
  }
}

export default App;
