import React from 'react';

function newsletter() {
	let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

	function saveEmail() {
        // TODO: newsletter endpoint
	}


	return (
		<>
			<div className='relative flex flex-col w-2/5 justify-between'>
				<h1 className='text-h5 mb-5'>{pt ? "Assina a nossa newsletter" : "Sign our newsletter"}</h1>
				<div>
					<input id="footer-newsletter-name" type="text" placeholder={pt ? "| Nome" : "| Name"} className='footer-newsletter-input bg-brown py-5' />
					<input id="footer-newsletter-email" type="text" placeholder='| Email' className='footer-newsletter-input bg-brown py-5' />
				</div>
			</div>
			<div className='relative flex flex-col w-3/5 items-end justify-between ml-7 top-2'>
				<div className='relative w-full h-full'>
					<div id="footer-newsletter-checkboxes" className='relative flex w-full'>
						<div className='footer-check-box relative flex m-2 justify-center items-center'>
							<input type="checkbox" className=' w-4 h-4  mx-2 hover: cursor-pointer' />
							<p className='text-p'>{pt ? "Novidades de Tech" : "Tech updates"}</p>
						</div>
					</div>
					<div id="footer-newsletter-checkboxes" className='relative flex w-full'>
						<div className='footer-check-box relative flex m-2 justify-center items-center'>
							<input type="checkbox" className=' w-4 h-4  mx-2 hover: cursor-pointer' />
							<p className='text-p'>{pt ? "Novidades da Vision D" : "Vision D news"}</p>
						</div>
					</div>
				</div>
				<div id="button-box-enviar" className='relative flex justify-end items-end'>
					<button onClick={saveEmail} id="button-enviar" className='bg-brown'>{pt ? "assinar" : "send"}</button>
				</div>
			</div>
		</>
	);
}

export default newsletter;