import React, { Component } from "react";

export class PartnershipAgreement extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <div className="">
        <div className="min-h-screen h-full bg-center bg-no-repeat bg-cover bg-errorHeader px-[8vw] py-[8rem] flex flex-col justify-center gap-8 text-left">
          <div className="oito:w-1/2">
            <h1 className="text-h3 font-GilroyBold seis:text-h2">
              {pt ? "Acordo de Parceria" : "Partnership Agreement"}
            </h1>
            <p>
              {pt ? "O objetivo desta parceria é combinar a perícia tecnológica da Vision D com as forças do nosso parceiro para fomentar a inovação, aumentar o alcance no mercado e criar soluções líderes de mercado."
                : "The aim of this partnership is to combine Vision D's expertise in technology with our partner’s strengths to foster innovation, enhance market reach, and create market-leading solutions."}
            </p>
          </div>
        </div>
        <div className="min-h-screen h-full flex flex-col px-[8vw] bg-[var(--blackBlue)] py-[4rem] text-left">
          <p>
            {pt ?
              <div>
                <strong>1. Introdução:</strong><br /><br />
                O objetivo desta parceria é combinar a perícia tecnológica da Vision D com as forças do nosso parceiro para fomentar a inovação, aumentar o alcance no mercado e criar soluções líderes de mercado. Este acordo abrange esforços colaborativos no desenvolvimento de aplicações, modelos de IA, soluções IoT, aplicações web, ERPs, back-offices e tecnologias Web3.
                <strong>2. Responsabilidades e Papéis:</strong><br /><br />
                A Vision D fornecerá infraestrutura tecnológica e perícia, enquanto o parceiro contribuirá com conhecimento de mercado e recursos adicionais conforme acordado.<br /><br />
                <strong>3. Obrigações Legais e de Conformidade:</strong><br /><br />
                Ambas as partes concordam em aderir a todas as leis e regulamentos aplicáveis nas suas jurisdições. A confidencialidade estrita será mantida em relação a qualquer informação e dados compartilhados, com aderência às regulamentações relevantes de proteção de dados.<br /><br />
                <strong>4. Disposições Financeiras:</strong><br /><br />
                As contribuições financeiras específicas de cada parceiro serão detalhadas em anexos a este acordo. Os lucros dos empreendimentos conjuntos serão divididos conforme acordado em acordos de projeto separados, detalhados em cronogramas anexados.<br /><br />
                <strong>5. Duração e Rescisão:</strong><br /><br />
                Este acordo é efetivo imediatamente após a assinatura e continuará por um período de três anos, sujeito a renovação.<br /><br />
                <strong>6. Resolução de Disputas:</strong><br /><br />
                Qualquer disputa será primeiramente tentada resolver através de mediação. Disputas não resolvidas estarão sujeitas a arbitragem de acordo com as regras da Câmara de Comércio Internacional.<br /><br />
                <strong>7. Emendas e Modificações:</strong><br /><br />
                Alterações ao acordo devem ser feitas por escrito e assinadas por representantes autorizados de ambas as partes.<br /><br />
                <strong>8. Anexos e Apêndices:</strong><br /><br />
                Planos de projeto e cronogramas financeiros: Documentos detalhados relacionados a projetos específicos e acordos financeiros serão adicionados a uma pasta partilhada com o parceiro.<br /><br />
                <strong>9. Implementação e Monitorização</strong><br /><br />
                <ul className="pl-4 list-disc list">
                  <li>Dashboard de Acesso: Os parceiros terão acesso a um dashboard para monitorizar contribuições, atividades e cronogramas de projetos.</li>
                  <li>Desenvolvimento e Aprovação de Estratégias: Planos estratégicos serão desenvolvidos em conjunto e aprovados através de reuniões agendadas.</li>
                  <li>Lançamento de Iniciativas Conjuntas: Os projetos terão início dentro de um prazo que varia de 1 a 6 meses, após planeamento detalhado.</li>
                </ul>
              </div>
              :
              <div>
                <strong>1. Introduction:</strong><br /><br />
                The aim of this partnership is to combine Vision D's expertise in technology with our partner’s strengths to foster innovation, enhance market reach, and create market-leading solutions. This agreement covers collaborative efforts in the development of applications, AI models, IoT solutions, web applications, ERPs, back-offices, and Web3 technologies.<br /><br />
                <strong>2. Roles and Responsibilities:</strong><br /><br />
                Vision D will provide technological infrastructure and expertise, while the partner will contribute market knowledge and additional resources as agreed upon.<br /><br />
                <strong>3. Legal and Compliance Obligations:</strong><br /><br />
                Both parties agree to adhere to all applicable laws and regulations in their respective jurisdictions. Strict confidentiality will be maintained regarding any shared information and data, with adherence to relevant data protection regulations.<br /><br />
                <strong>4. Financial Arrangements:</strong><br /><br />
                Specific financial contributions by each partner will be detailed in annexes to this agreement. Profits from joint ventures will be divided as agreed upon in separate project agreements, detailed in attached schedules.<br /><br />
                <strong>5. Duration and Termination:</strong><br /><br />
                This agreement is effective immediately upon signing and will continue for a period of three years, subject to renewal.<br /><br />
                <strong>6. Dispute Resolution:</strong><br /><br />
                Any disputes will first be attempted to resolve through mediation. Unresolved disputes will be subject to arbitration in accordance with the rules of the International Chamber of Commerce.<br /><br />
                <strong>7. Amendments and Modifications:</strong><br /><br />
                Changes to the agreement must be made in writing and signed by authorized representatives of both parties.<br /><br />
                <strong>8. Annexes and Appendices:</strong><br /><br />
                Project Plans and Financial Schedules: Detailed documents related to specific projects and financial arrangements will be added to a shared folder with the partner.<br /><br />
                <strong>9. Implementation and Monitoring</strong><br /><br />
                <ul className="pl-4 list-disc list">
                  <li>Access Dashboard: Partners will have access to a dashboard for monitoring contributions, activities, and project timelines.</li>
                  <li>Strategy Development and Approval: Strategic plans will be developed jointly and approved through scheduled meetings.</li>
                  <li>Launch of Joint Initiatives: Projects will commence within a timeline ranging from 1 to 6 months, following detailed planning.</li>
                </ul>
              </div>
            }
          </p>
        </div>
      </div>
    );
  }
}

export default PartnershipAgreement;
