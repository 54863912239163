import React from "react";
import UxIcon from "../../../content/images/icons-logos/logo-ux.png";

// import logoVisiond from "../../../content/images/icons-logos/logo-visiond.png";
import SpodyIcon from "../../../content/images/icons-logos/icon-spody.png";
import logoAPCH from "../../../content/images/icons-logos/apch.png";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

function goToForm() {
  localStorage.setItem("form", "UX & UI");
  window.location.replace('/form');
}

window.addEventListener('scroll', reveal);

function Ux() {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
  return (
    <section id="services-ux" className="relative flex h-screen bg-center bg-no-repeat bg-cover service-all bg-servicesUx ">
      <div className="reveal">
        <div className="relative flex w-full h-full px-24 py-24 services-container">
          <div className="relative flex items-center justify-start w-1/2 h-full services-container-left">
            <div className="relative flex flex-col justify-around h-full services-container-line mt-14">
            <div className="relative services-container-line-point code "></div>
              <div className="relative services-container-line-point app "></div>
              <div className="relative services-container-line-point ux bigger "></div>
              <div className="relative services-container-line-point web3 "></div>
              <div className="relative services-container-line-point ai "></div>
              <div className="relative services-container-line-point loaning"></div>
            </div>
            <div className="relative flex flex-col items-start justify-start h-full services-container-info p-14">
              <div className="services-container-info-img">
                <img src={UxIcon} alt="ux" className="w-16"/>
              </div>
              <div className="mt-6 text-left services-container-info-title">
                <h1 className="text-h3">{pt ? "Soluções de UX/UI" : "UX/UI Solutions"}</h1>
              </div>
              <div className="mt-6 text-left services-container-info-text">
              {pt ?
              <p>
                Nós ajudamos a melhorar a tua marca e a experiência do utilizador com designs visualmente impressionantes e intuitivos, criados pelos nossos designers de UX e UI. <br /><br /> Desde o planejamento dos wireframes até a criação de protótipos e designs de alta fidelidade, nós estamos aqui para ajudar a tua empresa a destacar-se num mercado competitivo e lotado.</p>
              :              
              <p>
                Enhance your brand and user experience with visually stunning and user-friendly designs created by our UX and UI designers. <br /><br /> From wireframes to prototypes and high-fidelity designs, we help you stand out in a crowded marketplace.
              </p>}
              </div>
            </div>
          </div>
          <div className="relative flex-col items-start justify-end hidden w-1/2 h-full pb-40 cinco:flex services-container-right">
              <div className="mb-10 services-container-portofolio-title">
                <h1 className="relative text-left text-white text-h3">{pt ? "Portfolio" : "Portfólio"}</h1>
              </div>
              <div className="seis:max-w-[25rem] max-w-[18rem] overflow-x-auto my-scrollbar relative flex">
                <div className="relative flex justify-start mb-2 services-container-portofolio-cards-container">
                    <div onClick={() => window.location.replace('https://wedrone.pt')} className="relative flex items-center justify-center mr-5 bg-center bg-cover cursor-pointer services-container-portofolio-card wedrone bg-WedroneBg hover:">
                    </div>
                    <div className="relative flex items-center justify-center mr-5 cursor-pointer services-container-portofolio-card spody hover:">
                      <img src={SpodyIcon} alt="alt" />
                    </div>
                    <div onClick={() => window.location.replace('https://casashistoricas.org')} className="relative flex items-center justify-center mr-5 bg-white bg-center bg-cover cursor-pointer services-container-portofolio-card hover:">
                      <img src={logoAPCH} alt="logo" className="relative w-16"/>
                    </div>
                    <div onClick={() => window.location.replace('https://worklab.pt')} className="relative flex items-center justify-center mr-5 bg-center bg-cover cursor-pointer services-container-portofolio-card bg-worklab hover:">
                    </div>
                    <div onClick={() => window.location.replace('https://montedahortinha.com')} className="relative flex items-center justify-center mr-5 bg-center bg-cover cursor-pointer services-container-portofolio-card bg-hortinha hover:">
                    </div>
                    </div>
                </div>
              </div>
          </div>
        <div onClick={() => goToForm()} className="absolute service-contact-btn bottom-16 right-16"><span className="mr-2">{pt ? "interessado" : "interested"}</span><span>→</span></div>
      </div>
    </section>
  );
}

export default Ux;