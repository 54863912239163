import React, { Component } from "react";
import Gif from '../../content/images/others/Logo_VisionD.gif';
import "./loading.css";

function disapear() {
  let screen = document.getElementById('loading-screen');
  screen?.classList.add('disapear');
}

export class LoadingScreen extends Component {
  render() {
    setTimeout(disapear, 1500);
    return (
      <div id="loading-screen" className="fixed z-[9999] w-screen h-screen flex justify-center items-center">
        <img src={Gif} alt="img" className="w-20"/>
      </div>
    );
  }
}

export default LoadingScreen;
