import {Header, SectionAbout, SectionServices, SectionDashboard, Parcerias} from './index'; 
import { Helmet } from "react-helmet-async";
import "./home.css"

function Home() {
  return (
    <>
      <Helmet>
        <title>Vision D</title>
        <meta name="description" content="Developers agency that creates the new era of digital success" />
        <link rel='canonical' href='/' />
      </Helmet>
      <Header/>
      <div id="home-body">
        <SectionAbout/>
        <SectionDashboard/>
        <Parcerias/>
        <SectionServices/>
        {/* <SectionCards/> */}
      </div>
    </>
    
  );
}

export default Home;
