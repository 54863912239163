import React, { Component } from 'react'

import './sectionDashboard.css';
import MainCard from "../../../content/images/home/mockup-dash.png";
import AchievementIcon from "../../../content/images/home/achievement2.png";

function ItSkill({...props}) {
  let className = "absolute h-4 rounded-full it-skill-line bg-[#19D7E2] ";
  className += props.knowledge;
  return (
    <div className="relative flex justify-center items-center w-full mb-4">
      <h5 className="text-h5 mr-5 w-1/3 pl-5">{props.skill}</h5>
      <div id="skills-card-progress-bg" className="relative w-1/2 bg-[#00000080] h-4 rounded-full">
        <div className={className}></div>
      </div>
    </div>
  );
}

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

window.addEventListener('scroll', reveal);

export class dashboard extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <section id="dashboard-all" className='relative grid items-center text-white text-left mt-60 pl-[10vw] mb-40 overflow-hidden'>
        <div id="dashboard-left" className='reveal relative flex flex-col justify-center w-full'>
          <p className='relative text-left w-full text-h2 mb-10'>{pt ? "Talentos de TI" : "IT Talents"}</p>
          <p className='text-h5 mb-10'>{pt ? "Simplifica o processo de recrutamento com a nossa plataforma que une os melhores talentos de TI através de uma dashboard customizada." : "Simplify your hiring process with our platform that matches you with top IT talent through a customizable dashboard."}</p>
          <div className='relative w-full flex justify-start'>
            <button onClick={() => window.location.replace("/dashboard")} id='dashboard-btn' className='relative px-4'>{pt ? "Entrar" : "Log in"} &ensp; →</button>
          </div>
        </div>
        <div className='relative w-full h-full flex items-center'>
          <img src={MainCard} alt="img" id="dahsboard-main"  className='reveal relative w-[60vw] right-[-15vw]'/>
          <div className="ach-card absolute reveal bottom-0 right-[15vw] p-5">
                <div className="ach-card-top relative flex w-full h-3/5 justify-start items-start">
                  <img src={AchievementIcon} alt="img" className="w-14 h-14 mr-5"/>
                  <p className="text-[1.1rem] text-left"><b>{pt ? "Mestre do React" : "React Master"}</b></p>
                </div>
                <div className="ach-card-bottom relative w-full h-2/5">
                  <p className="text-p text-left">{pt ? "Submete 10 projetos de react" : "Validate 10 react projects"}</p>
                </div>
              </div>
          <div id="card-skills" className="absolute reveal py-5 px-4 bottom-[5rem] left-[9vw]">
            <p className="text-h5 text-left mb-5">Skills</p>
            <div className="relative flex flex-col w-full text-left">
              <ItSkill skill="Web" knowledge="w-4/5"/>
              <ItSkill skill="UX/UI" knowledge="w-2/5"/>
              <ItSkill skill="Software" knowledge="w-full"/>
              <ItSkill skill="Cyber" knowledge="w-3/5"/>
              <ItSkill skill="Hardware" knowledge="w-1/5"/>
              <ItSkill skill="App" knowledge="w-4/5"/>
              <ItSkill skill="Networks" knowledge="w-3/5"/>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default dashboard;
