import React from 'react';
import { Helmet } from "react-helmet-async";
import emailjs from '@emailjs/browser';

import "./header.css";

import Map from '../../content/images/contact/contact-map.png';
import Warning from '../../content/images/others/warning.png';
import Checked from '../../content/images/others/checked.png';
import serviceSelectedIcon from '../../content/images/icons-logos/service-selected-icon.png';

let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

function clearForm() {
  (document.getElementById("contact-input-name") as HTMLInputElement).value = "";
  (document.getElementById("contact-input-email") as HTMLInputElement).value = "";
  (document.getElementById("contact-form-message") as HTMLInputElement).value = "";
  document.getElementById("contact-services-checkbox-one")?.classList.remove("active");
  document.getElementById("contact-services-checkbox-two")?.classList.remove("active");
  document.getElementById("contact-services-checkbox-three")?.classList.remove("active");
  document.getElementById("contact-services-checkbox-four")?.classList.remove("active");
  document.getElementById("contact-services-checkbox-five")?.classList.remove("active");
  document.getElementById("contact-services-checkbox-six")?.classList.remove("active");
  document.getElementById("contact-services-checkbox-pack")?.classList.remove("active");
  document.getElementById('contact-form-button')?.classList.remove('animate')
  document.getElementById('contact-service-selected')?.classList.add('hidden');
  document.getElementById('contact-services-add-btn')?.classList.remove('removeEmail');
}

function sendEmail() {
  var name = (document.getElementById("contact-input-name") as HTMLInputElement);
  var email = (document.getElementById("contact-input-email") as HTMLInputElement);
  var emailMessage = (document.getElementById("contact-form-message") as HTMLInputElement);
  var error = false;

  //Test name
  if (name.value === '' || name == null) {
    (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Nome inválido" : "Invalid name";
    name?.classList.add('error');
    error = true;
  } else {
    name?.classList.remove('error');
  }

  // Teste Email
  if (email.value === '' || email == null) {
    (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Email inválido" : "Invalid email";
    email?.classList.add('error');
    error = true;
  } else {
    email?.classList.remove('error');
  }

  //Test email pattern
  if (!email.value.match(/^[^ ]+@[^ ]+\.[a-z]{2,3}$/)) {
    (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Email inválido" : "Invalid email";
    email?.classList.add('error');
    error = true;
  } else {
    email?.classList.remove('error');
  }
    
  //Test message or services
  if (document.getElementById('contact-service-selected')?.classList.contains('hidden') && (emailMessage.value === '' || emailMessage === null)) {
    emailMessage?.classList.add('error');
    (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Escreve algo" : "Write a message";
    error = true;
  } else {
    emailMessage?.classList.remove('error');
  }

  //Test if there was an error
  if (error) {
    document.getElementById('contact-error-box')?.classList.add('animate');
    setTimeout(() => document.getElementById('contact-error-box')?.classList.remove('animate'), 10000)
    return ;
  }

  // Clear error
  document.getElementById('contact-error-box')?.classList.remove('animate');

  // Send email

  emailjs.send("service_aphshff","template_ckcj6ov",{
      name: name.value,
      email: email.value,
      message: emailMessage.value,
      budget: "No budget"
    }, 'TpqCZHn5_NLBs6Des');

  // Success animation and clear form
  document.getElementById("contact-form-button")?.classList.add("animate");
  setTimeout(() => clearForm(), 3000)
    
};

function Contact() {

  return (
    <div id="contact-all" className='bg-contactHeader bg-no-repeat bg-cover bg-start overflow-hidden'>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact us, without commitment" />
        <link rel='canonical' href='/contact' />
      </Helmet>
      <article id="contact-header" className="relative flex justify-center items-center h-screen">
        <div id="contact-header-container" className="relative flex w-full h-full px-24">
          <div id="contact-form-container" className="relative w-2/5 flex justify-start items-center pb-20">
            <div id='contact-error-box' className='absolute flex justify-center items-center bottom-[2.5rem] right-0 text-p p-2'><img src={Warning} alt="img" className='w-6 mr-2' /><p id='contact-error-box-text'></p></div>
            <main id="contact-form" className="relative flex flex-col w-full h-full text-left justify-end">
              <h1 id="contact-form-title-1" className="message relative text-h3 mb-4">{pt ? "Contacta-nos" : "Contact us,"} <br /> {pt ? "sem compromisso" : "without commitment"}</h1>
              <h2 id="contact-form-title-2" className="message relative text-h3 mb-5 hidden">&ensp;</h2>
              <textarea name="form" id="contact-form-message" placeholder={pt ? "| Escreve-nos algo" : "| Lets talk"} cols={30} rows={10}></textarea>
              {/* <div id="contact-form-change-buttons" className="relative flex justify-center items-center mt-7">
                <div id="contact-form-message" onClick={() => activeButton(1)} className="contact-form-change bg-messageIcon active bg-cover bg-no-repeat bg-center w-5 h-5 p-3 mr-7"></div>
                <div id="contact-form-calendar" onClick={() => activeButton(2)} className="contact-form-change bg-calendarIcon bg-cover bg-no-repeat bg-center w-5 h-5 p-3 mb-1"></div>
              </div> */}
              <textarea name="form" id="contact-form-agendar" className="hidden" placeholder="| Schedule" cols={30} rows={10}></textarea>
              <input id="contact-input-name" type="text" placeholder={pt ? "Nome" : "Name"} name="name" className="contact-form-input mt-5"/>
              <input id="contact-input-email" type="text" placeholder="Email" name="email" className="contact-form-input mt-5"/>
              <div className="relative flex justify-start items-center top-10">
                <button onClick={() => sendEmail()} id="contact-form-button" className="relative flex justify-center items-center text-h6 mr-1"><img src={Checked} alt="img" className='h-full absolute opacity-0' /><p>{pt ? "Enviar" : "Send"}</p></button>
                <div id='contact-service-selected' className='hidden relative w-10 h-10 p-2'><img src={serviceSelectedIcon} alt="img"/></div>
              </div>
            </main>
          </div>
          <div id="contact-header-info" className="relative flex justify-end items-end w-4/5 py-10 text-left z-40">
            <p><a href="mailto: geral@visiond.pt" className="hover: cursor-pointer hover:underline">geral@visiond.pt</a></p>
          </div>
        </div>
        <div className="contact-map-img">
          <div id="contact-header-right">
            <div id="contact-header-sede-info" className="absolute flex flex-col justify-between">
              <div>
                <div id="contact-header-map-sede" className="relative flex flex-col text-left w-1/2 mb-2">
                  <h1>&ensp;</h1>
                  <h1>Sede</h1>
                  <h1>Lisbon, Portugal</h1>
                </div>
                <div className="relative flex-col w-full">
                  <div id="contact-map-line-h" className="relative mb-5"></div>
                  <div className="relative w-full flex justify-end">
                    <div id="contact-map-line-d" className="relative right-3"></div>
                  </div>
                </div>
              </div>
              <div className="relative w-full flex justify-end items-end">
                <div id="contact-map-circle-out" className="relative w-5 h-5 flex justify-center items-center">
                  <div id="contact-map-circle-in" className="relative w-2 h-2 bg-white"></div>
                </div>
              </div>
            </div>
            <img src={Map} alt="map" className="relative w-full z-30"/>
          </div>
        </div>
      </article>
    </div>
    
  );
}

export default Contact;