import React from "react";

import StartupIcon from "../../../content/images/icons-logos/logo-security.png";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

window.addEventListener('scroll', reveal);

function Security() {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

    return (
      <section id="services-security" className="service-all relative flex h-screen bg-servicesSecurity bg-no-repeat bg-cover bg-center">
        <div className="reveal">
          <div className="services-container relative flex h-full w-full py-24 px-24">
            <div className="services-container-left relative h-full w-1/2 flex justify-start items-center">
              <div className="services-container-line relative flex flex-col h-full justify-around mt-14">
                <div className="services-container-line-point code relative "></div>
                <div className="services-container-line-point app relative "></div>
                <div className="services-container-line-point ux relative "></div>
                <div className="services-container-line-point web3 relative "></div>
                <div className="services-container-line-point ai relative "></div>
                <div className="services-container-line-point loaning relative"></div>
              </div>
              <div className="services-container-info relative flex flex-col h-full justify-start items-start p-14">
                <div className="services-container-info-img">
                  <img src={StartupIcon} alt="startup" />
                </div>
                <div className="services-container-info-title mt-6 text-left">
                  <h1 className="text-h3">{pt ? "Cibersegurança" : "Cyber-security"}</h1>
                </div>
                <div className="services-container-info-text mt-6 text-left">
                {pt ?
                <p>
                  Proteje a tua empresa contra ameaças online e garante conformidade com a indústria com a ajuda dos nossos especialistas em segurança. <br /><br /> Oferecemos avaliações de risco, auditorias de segurança e testes de vulnerabilidade para proteger os teus ativos e dados.  
                </p>
                :              
                <p>
                  Protect your business from online threats and ensure industry compliance with the help of our security experts. <br /><br /> We offer risk assessments, security audits, and vulnerability testing to safeguard your assets and data.
                </p>}
                </div>
              </div>
            </div>
            <div className="services-container-right relative flex flex-col h-full w-1/2 justify-end items-start">
              {/* <div className="services-container-portofolio relative relative flex flex-col h-1/2 ml-20">
                <div className="services-container-portofolio-title mb-10">
                  <h1 className="relative text-h3">Clients</h1>
                </div>
                  <div className="services-container-portofolio-cards-container relative flex justify-start">
                    <div onClick={() => window.location.replace('/contact')} className="services-container-portofolio-card none relative flex items-center justify-center mr-5 hover: cursor-pointer">
                      <h1 className="text-h3">+</h1>
                    </div>
                  </div>
              </div> */}
            </div>
          </div>
          <div onClick={() => window.location.replace('/budget')} className="service-contact-btn absolute bottom-16 right-16"><span className="mr-2">{pt ? "interessado" : "interested"}</span><span>→</span></div>
        </div>
      </section>
    );
}

export default Security;