import React from "react";
import AppImage from "../../../../content/images/icons-logos/logo-app.png";
import BtnVideo from './btnVideo';

const App = () => {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
        <div className="section2-selected-service w-2/5 flex flex-col justify-end items-center pr-24">
            <div className="flex flex-col justify-end items-start mb-24">
                <img src={AppImage} alt="startup" className="w-14 mb-10"/>
                <h2 className="text-h2 mb-6 text-left">{pt ? "Aplicações móveis" : "App development"}</h2>
                <p className="text-left mb-10">{pt ? "A equipa de produção trabalhará consigo para transformar as suas ideias em aplicações que impulsionam o crescimento e a inovação." : "Our team of skilled developers will work with you to transform your ideas into cutting-edge apps that drive growth and engagement."}</p>
                <div className="flex w-full justify-end items-end">
                    <div onClick={() => {window.localStorage.setItem('service', 'app'); window.location.replace('/services')}} className="section2-selected-service-button text-white w-48 h-12 border rounded-full bg-homeServicesBtn1 bg-no-repeat bg-cover bg-center">
                        <div className="section2-service-video app absolute flex w-full h-full">
                            <BtnVideo/>
                        </div>                        
                        <p className="z-50">{pt ? "saber mais" : "see more"} &ensp; → </p>
                    </div>                
                </div>
            </div>
        </div> 
    )
}

export default App;
