import React from "react";

import Video from "../../../content/images/home/home-about-code.png";

function code() {

  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  return (
    <div id="home-code-all" className="home-section1-sections flex justify-center items-center w-3/5">
      <img src={Video} alt="img" className="absolute home-section1-videos w-full h-full"></img>
      <div className="fade-black-img absolute h-full w-full rounded-[35px]"></div>
      <div id="home-code-container" className="home-section1-sections-title relative flex">
        <h1 className="relative text-h1">{pt ? "Construimos o futuro" : "We build the future"}</h1>
      </div>
    </div>
  );
}

export default code;
