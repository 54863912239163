import React from 'react';

import "./budget.css";

let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

function Header() {
  return (
    <main id="header-all" className='bg-budgetHeader bg-no-repeat bg-cover bg-center overflow-hidden h-screen flex flex-col justify-center items-center'>
        {pt ? 
        <>
            <p className='relative text-h2'>Diga-nos o que <b>quer</b></p>
            <p className='relative text-h2'>e <b>precisa</b></p>
            <h1>Acreditamos em serviços personalizados e queremos saber mais</h1>
        </>
        :
        <>
            <p className='relative text-h2'>Tell us what you <b>want</b></p>
            <p className='relative text-h2'>and <b>need</b></p>
            <h1>We believe in personalized services and want to know more</h1>
        </>
        }
        <button id='budget-header-button' onClick={() => window.location.replace("/budget#services-form1")} className='relative mt-5'>↓</button>
    </main>
    
  );
}

export default Header;