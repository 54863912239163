import React, { useState } from 'react';

import './apply.css';

import emailjs from '@emailjs/browser';
import { db, storage } from '../../firebase.js';
import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import FormsBackend from './forms/backend.json';
import FormsApp from './forms/app.json';
import FormsUx from './forms/ux.json';
import FormsFrontend from './forms/frontend.json';

import Warning from '../../content/images/others/warning.png';
import Checked from '../../content/images/others/checked.png';
import serviceSelectedIcon from '../../content/images/icons-logos/service-selected-icon.png';

let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

function Form() {

    let form;
    let formAnswers = new Map();
    let serviceName = "";
    const service = localStorage.getItem('apply');

    const [cv, setCv] = useState<any>(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [github, setGithub] = useState('');
    const [message, setMessage] = useState('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const file = target.files ? target.files[0] : null;
        if (!file)
            return;
        if (file.size > 1048576) {
            alert('File size must be less than 1MB');
            return;
        }
        if (file.type !== 'application/pdf') {
            alert('Invalid file type. Only pdf is allowed.');
            return;
        }
        setCv(file);
    };

    if (service === 'Frontend') {
        form = pt ? FormsFrontend.formPt : FormsFrontend.formEn;
        serviceName = "web";
    } else if (service === 'Backend') {
        form = pt ? FormsBackend.formPt : FormsBackend.formEn;
        serviceName = "web";
    } else if (service === 'App') {
        form = pt ? FormsApp.formPt : FormsApp.formEn;
        serviceName = "app";
    } else if (service === 'UX & UI') {
        form = pt ? FormsUx.formPt : FormsUx.formEn;
        serviceName = "ux";
    }

    async function sendEmail() {
        var error = false;

        // Test Answers

        if (form.length !== formAnswers.size) {
            (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Formulário incompleto" : "Incomplete form";
            error = true;
        }

        var formBudget = "";
        formAnswers.forEach((value, key) => {
            formBudget = formBudget + `${key} : ${value}\n\n`;
        });

        formBudget = formBudget + `Github/Portfolio: ` + github;

        //Test name
        if (name === '' || name == null) {
            (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Nome inválido" : "Invalid name";
            error = true;
        } else {
        }

        // Teste Email
        if (email === '' || email == null) {
            (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Email inválido" : "Invalid email";
            error = true;
        } else {
        }

        //Test email pattern
        if (!email.match(/^[^ ]+@[^ ]+\.[a-z]{2,3}$/)) {
            (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Email inválido" : "Invalid email";
            error = true;
        } else {
        }

        // Test if there was an error
        if (error) {
            document.getElementById('contact-error-box')?.classList.add('animate');
            setTimeout(() => document.getElementById('contact-error-box')?.classList.remove('animate'), 10000)
            return;
        }

        // Clear error
        document.getElementById('contact-error-box')?.classList.remove('animate');


        let cvUrl = "";
        // Save pdf
        if (cv) {
            try {
                const storageRef = ref(storage, `/cvAgents/${cv.name}`);
                const snapshot = await uploadBytes(storageRef, cv);
                const coverURL = await getDownloadURL(snapshot.ref);
                cvUrl = coverURL;
            } catch (error) {
                console.error('Error uploading pdf:', error);
                alert(pt ? "Ocorreu um erro ao salvar o pdf. Por favor tente novamente" : "An error occurred while saving the pdf. Please try again");
                return;
            }
        }

        const newAgent = {
            type: "Agent",
            lead: true,
            name: name,
            email: email,
            github: github,
            remarks: `Message from website: ${message} \n\nBudget Form:\n${formBudget}`,
            creationDate: new Date().toISOString(),
            active: true,
            role: service === 'UX & UI' ? 'Designer' : 'Programador',
            createdBy: 'website',
            cv: cvUrl
        }
        try {
            await setDoc(doc(db, 'websiteForms', email), newAgent);
            alert(pt ? "Formulário enviado com sucesso" : "Form submitted successfully");
        } catch (err) {
            console.log(err);
            alert(pt ? "Ocorreu um erro ao enviar o formulário. Por favor tente novamente" : "An error occurred while submitting the form. Please try again");
            return;
        }

        // Send email

        emailjs.send("service_aphshff", "template_ckcj6ov", {
            name: name,
            email: email,
            message: message,
            budget: formBudget
        }, 'TpqCZHn5_NLBs6Des');

        // Success animation and clear form
        document.getElementById("contact-form-button")?.classList.add("animate");
        setTimeout(() => window.location.replace('/'), 3000)

    };

    function resetAnswers(questionID: string) {
        let className = "." + questionID;
        let answers = document.querySelectorAll(className);
        for (let i = 0; i !== answers.length; i++) {
            answers[i].classList.remove('clicked');
            answers[i].classList.remove('text-white');
            answers[i].classList.remove(serviceName);
        }
    }

    function clickAnswer(question: string, questionID: string, answer: string, id: string, formId: number) {
        formAnswers.set(question, answer);
        resetAnswers(questionID);
        document.getElementById(id)?.classList.add('clicked');
        document.getElementById(id)?.classList.add('text-white');
        document.getElementById(id)?.classList.add(serviceName);
        if (document.getElementById('form-'.concat((formId + 1).toString())) !== null) {
            window.location.replace("/apply-form#form-".concat((formId + 1).toString()));
        } else {
            window.location.replace("/apply-form#send-form");
        }
    }

    function FormOption({ ...props }) {
        let id = "form-option-" + props.id + '-' + props.ans;
        let questionID = "form-option-" + props.id;
        return (
            <div onClick={() => clickAnswer(props.question, questionID, props.text, id, props.id)} id={id} className={questionID + " form-option relative flex flex-col justify-center items-center cursor-pointer p-3 text-center"}>
                <h2 className='text-h5'>{props.text}</h2>
            </div>
        );
    }

    return (
        <>
            <div id='form-all' className='relative flex flex-col px-[10vw]'>
                {form.map((question: any) => (
                    <div id={'form-' + question.id} className='slider-ele text-black text-left h-[50vh] my-[10rem] pt-20' key={question.id} >
                        <h2 className='relative w-full mb-10 text-white text-h2'>{question.question}</h2>
                        <div className='relative flex items-center justify-center w-full gap-4 py-10'>
                            <FormOption ans={1} question={question.question} id={question.id} text={question.answer1} />
                            <FormOption ans={2} question={question.question} id={question.id} text={question.answer2} />
                            {question.answer3 ? <FormOption ans={3} question={question.question} id={question.id} text={question.answer3} /> : null}
                            {question.answer4 ? <FormOption ans={4} question={question.question} id={question.id} text={question.answer4} /> : null}
                        </div>
                    </div>
                ))}
                <div id='send-form' className='relative w-full pt-16 mt-10'>
                    <div className="relative flex items-center justify-start w-full py-10">
                        <div id='contact-error-box' className='absolute flex justify-center items-center bottom-[2.5rem] right-0 text-p p-2'><img src={Warning} alt="img" className='w-6 mr-2' /><p id='contact-error-box-text'></p></div>
                        <div id="contact-form" className="relative flex flex-col justify-end w-full h-full text-left">
                            <h1 id="contact-form-title-1" className="relative my-4 message text-h3">{pt ? "Envia-nos as tuas respostas," : "Send your answers,"} <br /> {pt ? "contactamos-te em breve." : "we will contact you soon."}</h1>
                            <div className='grid w-full grid-cols-1 gap-8 mt-8 nove:grid-cols-2'>
                                <div className='flex items-center gap-8'>
                                    <label className='w-[20rem] text-left mandatory' htmlFor='contactName'>{pt ? "Nome" : "Name"}</label>
                                    <input className="border-white border-2 bg-[transparent] px-2 py-1 rounded-[8px] w-full outline-[var(--purple)]" type='text' id='contactName' name='contactName' value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className='flex items-center gap-8'>
                                    <label className='w-[20rem] text-left mandatory' htmlFor='contactEmail'>{pt ? "E-mail" : "Email"}</label>
                                    <input className="border-white border-2 bg-[transparent] px-2 py-1 rounded-[8px] w-full outline-[var(--purple)]" type='email' id='contactEmail' name='contactEmail' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className='flex items-center gap-8'>
                                    <label className='w-[20rem] text-left' htmlFor='contactMobile'>Github Link / Portfolio Link</label>
                                    <input className="border-white border-2 bg-[transparent] px-2 py-1 rounded-[8px] w-full outline-[var(--purple)]" type='text' name='contactMobile' value={github} onChange={(e) => setGithub(e.target.value)} />
                                </div>
                                <div className='flex items-center gap-8'>
                                    <label className='w-[20rem] text-left' htmlFor='contactMobile'>CV (pdf)</label>
                                    <input className="border-white border-2 bg-[transparent] px-2 py-1 rounded-[8px] w-full outline-[var(--purple)]" type='file' onChange={handleFileChange} />
                                </div>
                                <div className='flex items-center gap-8'>
                                    <label className='w-[20rem] text-left' htmlFor='contactPosition'>{pt ? "Escreva uma nota" : "Write a note"}</label>
                                    <input className="border-white border-2 bg-[transparent] px-2 py-1 rounded-[8px] w-full outline-[var(--purple)]" type='text' id='contactPosition' name='contactPosition' value={message} onChange={(e) => setMessage(e.target.value)} />
                                </div>
                            </div>
                            <div className="relative flex items-center justify-start mt-8">
                                <button onClick={() => sendEmail()} id="contact-form-button" className="relative flex items-center justify-center mr-1 text-h6"><img src={Checked} alt="img" className='absolute h-full opacity-0' /><p>{pt ? "Enviar" : "Send"}</p></button>
                                <div id='contact-service-selected' className='relative hidden w-10 h-10 p-2'><img src={serviceSelectedIcon} alt="img" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Form;