import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

export default function OurPosts({posts}:{posts:any}) {

    const [seeMore, setSeeMore] = useState(false);

    function selectPost(title:string) {
        localStorage.setItem('selectedPostTitle', title);
        console.log(title)
        console.log(localStorage.getItem('selectedPostTitle'))
        window.location.href = `/post`;
    }

    function PostCard({ ...props }) {
        return (
            <div onClick={() => selectPost(props.title)} className='cursor-pointer w-[80%] oito:w-full h-[12rem] bg-white rounded-[25px] flex flex-col place-self-center'>
                <img src={props.image} alt="blogimage" className='w-full h-[6rem] object-center object-cover rounded-[25px]' />
                <div className='p-2 flex flex-col justify-between text-left text-black '>
                    <h5 className='text-[1rem] font-bold'>{props.title}</h5>
                </div>
            </div>
        )
    }

    return (
        <div id='outposts' className='bg-[var(--darkBlue)] px-[8vw] py-[5rem]'>
            <h1 className='text-[2rem] text-left'><b>Our</b> Posts</h1>
            {
                (!posts || posts.length === 0)?
                    <div className='h-[15rem] flex items-center'>
                        <p className='w-full text-center'>There is no more posts</p>
                    </div>
                    :
                    <div className={`mt-[8rem] max-h-[52rem] ${seeMore ? "max-h-[200rem]" : 'max-h-[52rem]'} w-full grid grid-cols-1 oito:grid-cols-2 mil:grid-cols-3 gap-8 justify-center transition-all duration-500 overflow-hidden`}>
                        {
                            posts ?
                                (seeMore ? posts : posts.slice(0, 3)).map((post: any, index: number) => {
                                    return (post && <PostCard key={index} title={post.titulo} description={post.descricao} image={post.imagem} className="transition-opacity duration-300 transform ease-in-out" />)
                                })
                                :
                                <div className='relative w-full min-h-[10rem] flex justify-center items-center'>
                                    <CircularProgress color="inherit" />
                                </div>
                        }
                    </div>
            }
            <div className='mt-16 w-full flex justify-center transition-all duration-300'>
                <button onClick={() => setSeeMore(!seeMore)} className='w-[8rem] h-[2em] text-[1.2rem] bg-[transparent] border-2 border-white rounded-full hover:bg-white hover:text-black transition-all duration-300'>{seeMore ? 'see less' : "see more"}</button>
            </div>
        </div>
    );
}

