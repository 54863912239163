import React from "react";
import AIIcon from "../../../content/images/icons-logos/ai-logo.png";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

function goToForm() {
  localStorage.setItem("form", "AI");
  window.location.replace('/form');
}

window.addEventListener('scroll', reveal);

function Ai() {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
  return (
    <section id="services-ai" className="relative flex h-screen bg-center bg-no-repeat bg-cover service-all bg-servicesAi">
      <div className="relative w-full h-full reveal">
        <div className="relative flex w-full h-full px-24 py-24 services-container">
          <div className="relative flex items-center justify-start w-1/2 h-full services-container-left">
            <div className="relative flex flex-col justify-around h-full services-container-line mt-14">
              <div className="relative services-container-line-point code "></div>
              <div className="relative services-container-line-point app "></div>
              <div className="relative services-container-line-point ux "></div>
              <div className="relative services-container-line-point web3 "></div>
              <div className="relative services-container-line-point ai bigger "></div>
              <div className="relative services-container-line-point loaning"></div>
            </div>
            <div className="relative flex flex-col items-start justify-start h-full services-container-info p-14">
              <div className="services-container-info-img">
                <img src={AIIcon} alt="startup" className="w-16"/>
              </div>
              <div className="mt-6 text-left services-container-info-title">
                <h1 className="text-h3">{pt ? "Inteligencia" : "Artificial"}</h1>
                <h1 className="text-h3">{pt ? "artificial" : "inteligence"}</h1>
              </div>
              <div className="mt-6 text-left services-container-info-text">
              {pt ?
              <p>
                Transforma o teu negócio com as nossas soluções de inteligencia artificial. <br /><br /> Integrações de IA simplificam operações, aumenta a eficiência e abre novas oportunidades. Experimenta o poder da automação.</p>
              :              
              <p>
                Transform your business with our advanced AI solutions. <br /><br /> Our AI integrations streamlines operations, boosts efficiency, and unleashes new opportunities. Experience the power of automation today.                
              </p>}
              </div>
            </div>
          </div>
          <div className="relative hidden cinco:flex flex-col items-start justify-end w-1/2 h-full pb-40 services-container-right">
            <div className="mb-10 services-container-portofolio-title">
              <h1 className="relative text-left text-white text-h3">{pt ? "Portfolio" : "Portfólio"}</h1>
            </div>
            <div className="seis:max-w-[25rem] max-w-[18rem] overflow-x-auto my-scrollbar relative flex">
              <div className="relative flex justify-start mb-2 services-container-portofolio-cards-container">
                <div onClick={() => window.location.replace('https://worklab.pt')} className="relative flex items-center justify-center bg-center bg-cover cursor-pointer services-container-portofolio-card bg-worklab hover:">
                </div>
              </div>
            </div>
          </div>
          <div onClick={() => goToForm()} className="absolute service-contact-btn bottom-16 right-16"><span className="mr-2">{pt ? "interessado" : "interested"}</span><span>→</span></div>
        </div>
      </div>
    </section>
  );
}

export default Ai;