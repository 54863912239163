import React, { Component } from "react";
import "./header.css";

export class header extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <article id="services-header" className="relative flex flex-col justify-center items-center h-screen bg-servicesHeader bg-no-repeat bg-cover bg-center mt-0">
          <div id="services-header-main" className="text-h1 mb-2">
            <p>{pt ? "Nossos serviços" : "Our services"}</p>
            <p>{pt ? "e tecnologias" : "& technology"}</p>
          </div>
          <main id="services-header-description">
            <h1>{pt ? "Tudo o que criamos, produzimos e lançamos" : "Everything that we create, code and launch"}</h1>
          </main>
          {/* <div className="relative flex text-h5 mt-6 w-full justify-center items-center">
            <button id="services-header-button2" onClick={() => window.location.replace('/budget')}>{pt ? "Pedir orçamento" : "I want a budget"}</button>
          </div> */}
          <div className="text-h5 mt-10">
            <div id="services-header-button" onClick={() => window.location.replace("/services#services-code")} className="relative flex justify-center items-center">↓</div>
          </div>
      </article>
    );
  }
}

export default header;
