import React, { Component } from "react";
import "./header.css";

export class header extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <article id="portfolio-header" className="relative flex flex-col justify-center items-center h-screen bg-portfolioHeader bg-no-repeat bg-cover bg-center mt-0">
          <div id="portfolio-header-main" className="text-h1 mb-2">
            <p>{pt ? "O nosso portfólio" : "Our portfolio"}</p>
          </div>
          <main id="portfolio-header-description">
            <h1>{pt ? "Uma amostra do que já poduzimos" : "A sample of what we have developed"}</h1>
          </main>
          <div className="text-h5 mt-10">
            <div id="portfolio-header-button" onClick={() => window.location.replace("/portfolio#portfolio-web")} className="relative flex justify-center items-center">↓</div>
          </div>
      </article>
    );
  }
}

export default header;
