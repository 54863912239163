import React from "react";

import Video from "../../../content/images/home/home-about-launch.png";

function launch() {

  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  return (
    <div id="home-launch-all" className="home-section1-sections flex justify-center items-center bg-no-repeat bg-cover bg-center w-3/5">
      <img src={Video} alt="img" className="absolute home-section1-videos w-full h-full"></img>
      <div className="fade-black-img absolute h-full w-full rounded-[25px]"></div>
      <div id="home-launch-container" className=" home-section1-sections-title relative flex">
        <h1 className="relative text-h1">{pt ? "Lançamos o novo presente" : "We launch the new present"}</h1>
      </div>
    </div>
  );
}

export default launch;
