import React from "react";
import { useState } from "react";

import Code from './services/code';
import App from './services/app';
// import Cloud from './services/cloud';
// import Security from './services/security';
import Ux from './services/ux';
import Blockchain from './services/blockchain';
import Ai from './services/ai';
// import Consulting from './services/consulting';
// import Loaning from './services/loaning';
import StartUps from './services/startups';

import WebImage from "../../../content/images/icons-logos/logo-code.png";
import AppImage from "../../../content/images/icons-logos/logo-app.png";
// import HardwareImage from "../../../content/images/icons-logos/logo-hardware.png";
// import SecurityImage from "../../../content/images/icons-logos/logo-security.png";
import UxImage from "../../../content/images/icons-logos/logo-ux.png";
import Web3Image from "../../../content/images/icons-logos/web3-logo.png";
import AIImage from "../../../content/images/icons-logos/ai-logo.png";

// import LoaningImage from "../../../content/images/icons-logos/logo-loaning.png";
import StartupsImage from "../../../content/images/icons-logos/logo-startups.png";

import "./sectionServices.css";

function Services( { service = 1 } ) {

  let serviceId = "home-SectionServices-service-";
  serviceId += service;

  document.getElementById("home-SectionServices-service-1")?.classList.remove("selected");
  document.getElementById("home-SectionServices-service-2")?.classList.remove("selected");
  document.getElementById("home-SectionServices-service-3")?.classList.remove("selected");
  document.getElementById("home-SectionServices-service-4")?.classList.remove("selected");
  document.getElementById("home-SectionServices-service-5")?.classList.remove("selected");
  document.getElementById("home-SectionServices-service-6")?.classList.remove("selected");
  document.getElementById(serviceId)?.classList.add("selected");

  if (service === 1) {
    return (<Code />);
  }
  else if (service === 2) {
    return (<App />);
  }
  else if (service === 3) {
    return (<StartUps/>);
  }
  else if (service === 4) {
    return (<Ux />);
  }
  else if (service === 5) {
    return (<Blockchain/>);
  }
  else if (service === 6) {
    return (<Ai />);
  } else {
    return null;
  }
}

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

window.addEventListener('scroll', reveal);

function SectionServices() {
  const [service, setService] = useState<number>(1);
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  return (
    <section id="home-SectionServices" className="relative flex">
      <div id="home-SectionServices-services" className="relative justify-start items-start w-3/5 mb-20">
          <div id="home-SectionServices-title" className="reveal">
            <p className="text-h2 text-left mb-20">{pt ? "Serviços" : "Our services"}</p>
          </div>
          <div id="home-SectionServices-services-grid" className="reveal grid grid-cols-3 grid-flow-row gap-y-20 mb-32 text-white font-GilroyBold">
              <div id="home-SectionServices-service-1" className="service selected" onClick={() => setService(1)}>
                  <img src={WebImage} alt="code"/>
                  <h2 className="text-p">Web</h2>
              </div>
              <div id="home-SectionServices-service-2" className="service" onClick={() => setService(2)}>
                  <img src={AppImage} alt="hardware"/>
                  <h2 className="text-p">App</h2>
              </div>
              <div id="home-SectionServices-service-4" className="service" onClick={() => setService(4)}>
                  <img src={UxImage} alt="xd"/>
                  <h2 className="text-p">UX & UI</h2>
              </div>
              <div id="home-SectionServices-service-5" className="service " onClick={() => setService(5)}>
                  <img src={Web3Image} alt="web3"/>
                  <h2 className="text-p">Blockchain</h2>
              </div>
              <div id="home-SectionServices-service-6" className="service" onClick={() => setService(6)}>
                  <img src={AIImage} alt="startups"/>
                  <h2 className="text-p">{pt ? "IA" : "AI"}</h2>
              </div>
              <div id="home-SectionServices-service-3" className="service" onClick={() => setService(3)}>
                  <img src={StartupsImage} alt="startup"/>
                  <h2 className="text-p">Startups</h2>
              </div>
          </div>
      </div>
      <Services service={service} /> 
    </section>
  );
}

export default SectionServices;
