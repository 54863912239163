import React from "react";
import "./sectionCards.css";

import Card1 from "../../../content/images/home/home-cards-card1.png";
import Card2 from "../../../content/images/home/home-cards-card2.png";
import Card3 from "../../../content/images/home/home-cards-card3.png";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

window.addEventListener('scroll', reveal);

function SectionCards() {
    return (
      <div id="sectionCards-all" className="relative text-white px-20">
        <div id="sectionCards-container" className="relative flex h-full flex-col justify-around">
          <div id="sectionCards-container-title" className="reveal relative text-h3 text-left mb-10 w-full">
            <h1 className="w-full">Tell us what you need</h1>
          </div>
          <div id="sectionCards-container-cards-container" className="relative flex justify-around h-3/5 mb-40">
            <div className="sectionCards-container-card reveal flex flex-col relative justify-end items-start text-left">
              <img src={Card1} alt="card1" className="relative w-full h-full" />
              <div className="content absolute flex flex-col p-6 w-full h-full justify-end items-start">
                <h1 className="content-title text-h4">IT Specialists</h1>
                <h1 className="content-text text-p mb-4">Effortlessly manage IT operations with our specialists dashboard. Track metrics, optimize performance, and streamline workflow.</h1>
              </div>
            </div>
            <div className="sectionCards-container-card reveal flex flex-col relative justify-end items-start text-left">
              <img src={Card2} alt="card2" className="relative w-full h-full" />
              <div className="content absolute flex flex-col p-6 w-full h-full justify-end items-start">
                <h1 className="content-title text-h4">Creating digital projects</h1>
                <h1 className="content-text text-p mb-4">Revolutionize your business with our digital development expertise. Contact us now for innovative solutions!</h1>
              </div>
            </div>
            <div className="sectionCards-container-card reveal flex flex-col relative justify-end items-start text-left">
              <img src={Card3} alt="card3" className="relative w-full h-full" />
              <div className="content absolute flex flex-col p-6 w-full h-full justify-end items-start">
                <h1 className="content-title text-h4">IT infrastructures management</h1>
                <h1 className="content-text text-p mb-4">Maximize your system's performance and security with our IT infrastructure management services.</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SectionCards;
