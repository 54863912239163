import React from 'react';

import './budget.css';

import emailjs from '@emailjs/browser';
import { db } from '../../firebase.js';

import FormsWeb from './forms/web.json';
import FormsApp from './forms/app.json';
import FormsUx from './forms/ux.json';
import FormsWeb3 from './forms/web3.json';
import FormsAi from './forms/ai.json';
import FormsConsulting from './forms/consulting.json';

import Warning from '../../content/images/others/warning.png';
import Checked from '../../content/images/others/checked.png';
import serviceSelectedIcon from '../../content/images/icons-logos/service-selected-icon.png';
import { doc, setDoc } from 'firebase/firestore';

let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

function Form() {

    let form;
    let formAnswers = new Map();
    let serviceName = "";
    const service = localStorage.getItem('form');

    if (service === 'Web') {
        form = pt ? FormsWeb.formPt : FormsWeb.formEn;
        serviceName = "web";
    } else if (service === 'App') {
        form = pt ? FormsApp.formPt : FormsApp.formEn;
        serviceName = "app";
    } else if (service === 'UX & UI') {
        form = pt ? FormsUx.formPt : FormsUx.formEn;
        serviceName = "ux";
    } else if (service === 'Blockchain') {
        form = pt ? FormsWeb3.formPt : FormsWeb3.formEn;
        serviceName = "web3";
    } else if (service === 'AI') {
        form = pt ? FormsAi.formPt : FormsAi.formEn;
        serviceName = "ai";
    } else if (service === 'Consulting') {
        form = pt ? FormsConsulting.formPt : FormsConsulting.formEn;
        serviceName = "consulting";
    }

    async function sendEmail() {
        var name = (document.getElementById("contact-input-name") as HTMLInputElement);
        var email = (document.getElementById("contact-input-email") as HTMLInputElement);
        var emailMessage = (document.getElementById("contact-input-msg") as HTMLInputElement);
        var error = false;

        // Test Answers

        if (form.length !== formAnswers.size) {
            (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Formulário incompleto" : "Incomplete form";
            error = true;
        }

        var formBudget = "";
        formAnswers.forEach((value, key) => {
            formBudget = formBudget + `${key} : ${value}\n\n`;
        });

        //Test name
        if (name.value === '' || name == null) {
            (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Nome inválido" : "Invalid name";
            name?.classList.add('error');
            error = true;
        } else {
            name?.classList.remove('error');
        }

        // Teste Email
        if (email.value === '' || email == null) {
            (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Email inválido" : "Invalid email";
            email?.classList.add('error');
            error = true;
        } else {
            email?.classList.remove('error');
        }

        //Test email pattern
        if (!email.value.match(/^[^ ]+@[^ ]+\.[a-z]{2,3}$/)) {
            (document.getElementById('contact-error-box-text') as HTMLParagraphElement).textContent = pt ? "Email inválido" : "Invalid email";
            email?.classList.add('error');
            error = true;
        } else {
            email?.classList.remove('error');
        }

        // Test if there was an error
        if (error) {
            document.getElementById('contact-error-box')?.classList.add('animate');
            setTimeout(() => document.getElementById('contact-error-box')?.classList.remove('animate'), 10000)
            return;
        }

        // Clear error
        document.getElementById('contact-error-box')?.classList.remove('animate');

        const newClient = {
            type: "Client",
            lead: true,
            name: name.value,
            email: email.value,
            remarks: `Message from website: ${emailMessage.value} \n\nBudget Form:\n${formBudget}`,
            creationDate: new Date().toISOString(),
            active: true,
            createdBy: 'website'
        }
        try {
            await setDoc(doc(db, 'websiteForms', email.value), newClient);
            alert(pt ? "Formulário enviado com sucesso" : "Form submitted successfully");
        } catch (err) {
            console.log(err);
            alert(pt ? "Ocorreu um erro ao enviar o formulário. Por favor tente novamente" : "An error occurred while submitting the form. Please try again");
            return;
        }

        // Send email to us
        emailjs.send("service_aphshff", "template_ckcj6ov", {
            name: name.value,
            email: email.value,
            message: emailMessage.value,
            budget: formBudget
        }, 'TpqCZHn5_NLBs6Des');

        // Success animation and clear form
        setTimeout(() => window.location.replace('/'), 3000)

    };

    function resetAnswers(questionID: string) {
        let className = "." + questionID;
        let answers = document.querySelectorAll(className);
        for (let i = 0; i !== answers.length; i++) {
            answers[i].classList.remove('clicked');
            answers[i].classList.remove('text-white');
            answers[i].classList.remove(serviceName);
        }
    }

    function clickAnswer(question: string, questionID: string, answer: string, id: string, formId: number) {
        formAnswers.set(question, answer);
        resetAnswers(questionID);
        document.getElementById(id)?.classList.add('clicked');
        document.getElementById(id)?.classList.add('text-white');
        document.getElementById(id)?.classList.add(serviceName);
        if (document.getElementById('form-'.concat((formId + 1).toString())) !== null) {
            window.location.replace("/form#form-".concat((formId + 1).toString()));
        } else {
            window.location.replace("/form#send-form");
        }
    }

    function FormOption({ ...props }) {
        let id = "form-option-" + props.id + '-' + props.ans;
        let questionID = "form-option-" + props.id;
        return (
            <div onClick={() => clickAnswer(props.question, questionID, props.text, id, props.id)} id={id} className={questionID + " form-option relative flex flex-col justify-center items-center cursor-pointer p-3 text-center"}>
                <h2 className='text-h5'>{props.text}</h2>
            </div>
        );
    }

    return (
        <>
            <div id='form-all' className='relative flex flex-col px-[10vw]'>
                {form.map((question: any) => (
                    <div id={'form-' + question.id} className='slider-ele text-black text-left h-[50vh] my-[10rem] pt-20' key={question.id} >
                        <h2 className='relative w-full mb-10 text-white text-h2'>{question.question}</h2>
                        <div className='relative flex items-center justify-center w-full gap-4 py-10'>
                            <FormOption ans={1} question={question.question} id={question.id} text={question.answer1} />
                            <FormOption ans={2} question={question.question} id={question.id} text={question.answer2} />
                            {question.answer3 ? <FormOption ans={3} question={question.question} id={question.id} text={question.answer3} /> : null}
                            {question.answer4 ? <FormOption ans={4} question={question.question} id={question.id} text={question.answer4} /> : null}
                        </div>
                    </div>
                ))}
                <div id='send-form' className='relative pt-16 mt-10'>
                    <div id="contact-form-container" className="relative flex items-center justify-start w-2/5 py-10">
                        <div id='contact-error-box' className='absolute flex justify-center items-center bottom-[2.5rem] right-0 text-p p-2'><img src={Warning} alt="img" className='w-6 mr-2' /><p id='contact-error-box-text'></p></div>
                        <div id="contact-form" className="relative flex flex-col justify-end w-full h-full text-left">
                            <h1 id="contact-form-title-1" className="relative my-4 message text-h3">{pt ? "Envie-nos as suas respostas," : "Send your answers"} <br /> {pt ? "nós arranjamos as soluções." : "we'll find the solutions."}</h1>
                            <input id="contact-input-name" type="text" placeholder={pt ? "| Nome" : "| Name"} name="name" className="mt-5 contact-form-input" />
                            <input id="contact-input-email" type="text" placeholder="| Email" name="email" className="mt-5 contact-form-input" />
                            <input id="contact-input-msg" type="text" placeholder={pt ? "| Escreva uma nota" : "| Write a note"} name="email" className="mt-5 mb-10 contact-form-input" />
                            <div className="relative flex items-center justify-start">
                                <button onClick={() => sendEmail()} id="contact-form-button" className="relative flex items-center justify-center mr-1 text-h6"><img src={Checked} alt="img" className='absolute h-full opacity-0' /><p>{pt ? "Enviar" : "Send"}</p></button>
                                <div id='contact-service-selected' className='relative hidden w-10 h-10 p-2'><img src={serviceSelectedIcon} alt="img" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Form;