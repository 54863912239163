import React, { useEffect, useState } from 'react';
import Header from './header';
import LatestPost from './latestPost';
import OurPosts from './ourPosts';
import CircularProgress from '@mui/material/CircularProgress';

export default function Blog() {
    const [posts, setPosts] = useState<any>(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/posts/${process.env.REACT_APP_BLOG_USER}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const posts = await response.json();
                const organizePosts = posts.sort((a:any, b:any) => {
                    const dateA = new Date(a.dataCriacao.split('/').reverse().join('-')).getTime();
                    const dateB = new Date(b.dataCriacao.split('/').reverse().join('-')).getTime();
                    return dateA - dateB; 
                });
                setPosts(organizePosts);
            } catch (error) {
                console.error('Fetching user posts failed:', error);
                return null;
            }
        }
        fetchData();
    }, []);

    if (!posts) {
        return (
            <div className='relative flex items-center justify-center w-full min-h-screen'>
                <CircularProgress color="inherit" />
            </div>
        )
    }
    return (
        <div>
            <Header />
            <LatestPost postData={posts[0]} />
            <OurPosts posts={posts.slice(1)} />
        </div>
    );
}

