import React from 'react';
import { Helmet } from "react-helmet-async";

import "./budget.css";

import Header from "./header";
import Services from "./services";

function Budget() {
  return (
    <div id="budget-all">
      <Helmet>
        <title>Budget</title>
        <meta name="description" content="We believe in personalized services and want to know more" />
        <link rel='canonical' href='/budget' />
      </Helmet>
      <Header/>
      <Services/>
    </div>
    
  );
}

export default Budget;