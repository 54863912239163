import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: "visiond-erp-f9dae.firebaseapp.com",
    projectId: "visiond-erp-f9dae",
    storageBucket: "visiond-erp-f9dae.appspot.com",
    messagingSenderId: "955932201309",
    appId: process.env.REACT_APP_ID,
    measurementId: "G-4N2W9VE6TB"
  };

  export const firebaseApp = initializeApp(firebaseConfig);
  export const db = getFirestore(firebaseApp);
  export const storage = getStorage(firebaseApp);
