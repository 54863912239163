import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './blog.css';
import Logo from '../../content/images/icons-logos/logo-visiond.png';
import Markdown from 'react-markdown';

export default function PostPage() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

    const [post, setPost] = useState<any>(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/posts/${process.env.REACT_APP_BLOG_USER}/${localStorage.getItem('selectedPostTitle') || ''}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const post = await response.json();
                setPost(post);
            } catch (error) {
                console.error('Fetching user posts failed:', error);
                return null;
            }
        }
        fetchData();
    }, []);

    function saveEmail() {

    }

    if (post === 'none') {
        return (
            <div className='py-[10rem] bg-[var(--darkBlue)] min-h-screen flex justify-center'>
                <div className='w-[70%] text-left'>
                    <h1 className='text-[2rem] font-bold'>{pt ? 'Nenhum artigo encontrado' : 'No article found'}</h1>
                    <h2 onClick={() => window.location.replace('/blog')} className=' cursor-pointer mt-4 w-[7rem]'>←   {pt ? 'voltar' : 'go back'}</h2>
                </div>
            </div>
        )
    }

    if (!post) {
        return (
            <div className='relative w-full min-h-screen flex justify-center items-center bg-[var(--darkBlue)]'>
                <CircularProgress color="inherit" />
            </div>
        )
    }

    return (
        <div className='w-full py-[8rem] bg-[var(--darkBlue)] min-h-screen flex flex-col items-center'>
            <div className='relative text-left flex flex-col gap-6 w-full items-center'>
                <div className='w-[90%] cinco:w-[70%] flex flex-col gap-6 text-left'>
                    <h1 className='text-[1.5rem] cinco:text-[2rem] font-bold text-[#c318f9]'>{post.titulo}</h1>
                    <h2 className='text-[1.2rem] cinco:text-[1.5rem] font-bold'>{post.descricao}</h2>
                    <div>
                        <p>{pt ? 'De:' : 'By:'} <b>{post.autor}</b></p>
                        <p>{pt ? 'Publicado pela:' : 'Published by:'} <b className='text-[#c318f9]'>Vision D</b></p>
                        <p>{pt ? 'Last update:' : 'Último update:'} &ensp; <b>{post.dataEdicao}</b></p>
                    </div>
                    <img src={post.imagem} alt="postImage" className='h-[20rem] rounded-[25px] object-cover' />
                </div>
                <div className='relative py-[2rem] border-y-[1px] border-[grey] flex flex-col items-center w-full min-h-[25rem]'>
                <section className="markdown w-[90%] cinco:w-[70%] text-white" style={{ whiteSpace: 'pre-wrap' }}>
                    <Markdown className="w-full">{post.article}</Markdown>
                </section>
                    {
                        !post.seeAll &&
                        <>
                            <div id='blogpage-subscribe' className='absolute w-full justify-center items-center h-[50%] min-h-[20rem] bottom-[2rem] flex flex-col gap-8 z-[1]'>
                                <div className='w-full h-full justify-center items-center bg-[var(--darkBlue)] flex flex-col gap-8 z-[2] py-8'>
                                    <p className='text-balance w-[60%] text-center text-[1.2rem]'>{pt ? "Subscreva a nossa newsletter para ler a história completa." : "Subscribe our newsletter to read the full story."}</p>
                                    <p className='text-balance w-full cinco:w-[60%] text-center text-[1rem]'>{pt ? "O autor disponibilizou esta história apenas para os membros do Vision D. Se é novo no Vision D, subscreva a nossa newsletter para ler esta história por nossa conta." : "The author made this story available to Vision D members only. If you’re new to Vision D, Subscribe our newsletter to read this story on us."}</p>
                                    <div className='w-[85%] cinco:w-[25rem] pb-8 bg-white rounded-[35px] flex items-center flex-col'>
                                        <div className='flex justify-center gap-4 items-center font-bold'>
                                            <img src={Logo} alt="logo" className='w-[8rem] invert' />
                                            <p className='text-[var(--pink)] mt-1'>NEWSLETTER</p>
                                        </div>
                                        <div className='relative w-[70%] flex items-center'>
                                            <input type="email" className='rounded-full border-[2px] w-full h-[2.5rem] pl-4 pr-1 border-[gray] flex items-center justify-between outline-none text-black' placeholder='Enter your email' />
                                            <div onClick={saveEmail} className='absolute right-0 w-8 h-8 bg-black rounded-full justify-center items-center flex cursor-pointer mr-1'>
                                                🡒
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

