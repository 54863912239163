import React, { Component } from "react";

export class Error extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <div id="error-header" className="relative flex flex-col items-center justify-center h-screen bg-center bg-no-repeat bg-cover bg-errorHeader ">
        <h1 className="relative text-h1">{pt ? "Erro 404" : "404 Error"}</h1>
        <h1 className="relative mt-4">{pt ? "Esta página não existe" : "This page does not exist"}</h1>
      </div>
    );
  }
}

export default Error;