import React, { Component } from "react";
import "./web.css";

import Mockup1 from '../../../content/images/portfolio/web/mockup1.png';
import Mockup2 from '../../../content/images/portfolio/web/mockup2.png';
import Mockup3 from '../../../content/images/portfolio/web/mockup3.png';
import Laptop from '../../../content/images/portfolio/web/laptop.png';
import WedroneLogo from '../../../content/images/portfolio/web/wedrone-logo.png';

export class Web extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <section id="portfolio-web" className="relative min-h-screen bg-portfolioWeb flex justify-between py-20">
        <h2 className="absolute w-full text-right text-h2 top-16 right-[8vw]">Websites</h2>
        <div id="portfolio-web-left" className="relative w-1/2 flex justify-center items-center ">
          <img className="web-mockup a" src={Mockup1} alt="img" />
          <img className="web-mockup b" src={Mockup2} alt="img" />
          <img className="web-mockup c" src={Mockup3} alt="img" />
          <img id="laptop" src={Laptop} alt="img" className="reveal"/>
        </div>
        <div id="portfolio-web-info" className="relative h-full w-1/2 flex flex-col justify-center text-left text-h5 pt-40 pr-24 pl-14">
          <div className="">
            <img id="portfolio-wedrone-logo" src={WedroneLogo} alt="img" className="w-32 mb-5 reveal"/>
          </div>
          {pt ?
            <p className="reveal">
              O website da Wedrone marcou o nosso projeto de estreia, servindo como um testemunho da nossa capacidade para traduzir a visão dos clientes numa presença digital elegante e contemporânea. <br /><br /> Através de uma colaboração próxima com a Wedrone, conseguimos efetivamente traduzir a sua visão numa plataforma digital tangível. 
            </p>
            :              
            <p className="reveal">
              The Wedrone website marked our debut project, serving as a testament to our ability to translate our clients's vision into a sleek and contemporary digital presence. <br /><br /> By collaborating closely with Wedrone, we effectively translated their vision into a tangible digital platform. 
            </p>
          }
          <span className="mt-8 text-p cursor-pointer reveal" onClick={() => window.open('https://wedrone.pt', '_blank')}>{pt ? "Abrir website" : "Open website"}</span>
        </div>
      </section>
    );
  }
}

export default Web;
