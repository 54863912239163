import React from "react";
import AiImage from "../../../../content/images/icons-logos/ai-logo.png";
import BtnVideo from './btnVideo';

const Ai = () => {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
        <div className="section2-selected-service w-1/3 flex flex-col justify-end items-center">
            <div className="flex flex-col justify-end items-start mb-24">
                <img src={AiImage} alt="startup" className="w-14 mb-10"/>
                <h2 className="text-h2 mb-6 text-left">{pt ? "Inteligencia artificial" : "Artificial Inteligence"}</h2>
                <p className="text-left">{pt ? "Desbloqueie o poder da IA para o seu negócio com as nossas soluções avançadas. Experimente o futuro da automação." : "Empower your business with our advanced AI solutions. Experience the future of automation."}</p>
                <div className="flex w-full justify-end items-end mt-10">
                    <div onClick={() => {window.localStorage.setItem('service', 'ai'); window.location.replace('/services')}} className="section2-selected-service-button text-white w-48 h-12 border rounded-full bg-homeServicesBtn1 bg-no-repeat bg-cover bg-center">
                        <div className="section2-service-video ai absolute flex w-full h-full">
                            <BtnVideo/>
                        </div>                        
                        <p className="z-50">{pt ? "saber mais" : "see more"} &ensp; → </p>
                    </div>    
                </div>
            </div>
        </div> 
    )
}

export default Ai;
