import React from 'react';

import WebImage from "../../content/images/icons-logos/logo-code.png";
import AppImage from "../../content/images/icons-logos/logo-app.png";
// import HardwareImage from "../../content/images/icons-logos/logo-hardware.png";
// import SecurityImage from "../../content/images/icons-logos/logo-security.png";
import UxImage from "../../content/images/icons-logos/logo-ux.png";
import Web3Image from "../../content/images/icons-logos/web3-logo.png";
import AIImage from "../../content/images/icons-logos/ai-logo.png";
// import ConsultingImage from "../../content/images/icons-logos/logo-loaning.png";
import StartupsImage from "../../content/images/icons-logos/logo-startups.png";

let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

function doForm(service:string) {
    if (service === "Startups") {
        window.location.replace("https://startups.visiond.pt");
        return ;
    }
    localStorage.setItem("form", service);
    window.location.replace('/form');
}

function Services() {

    function Service({...props}) {
        let w = "w-8 mb-1";
        if (props.service === "App") {
            w = "w-6 mb-1";
        }

        return (
            <div  onClick={() => doForm(props.service) } className="service-form-option relative flex flex-col justify-between items-center cursor-pointer px-5 py-7">
                <h2 className="relative w-full">{props.text}</h2>
                <div className="form-service relative flex justify-center items-center text-black rounded-[25px] w-[13rem] h-[13rem] p-5 text-center cursor-pointer">
                    <img src={props.img} alt="img" className={w} />
                    <h3 className='text-p'>{props.service}</h3>
                </div>
            </div>
        )
    }

    return (
        <div id='services-form1' className='relative bg-[#F1F1F1] h-[110vh] flex flex-col text-black text-left overflow-hidden justify-center px-[6vw]'>
            <div id='form-slider-services-container' className='slider-ele relative'>
                <h2 className='relative w-full text-h2 mb-20'>{pt ? "Que tipo de serviço deseja?" : "What type of service do you desire?"}</h2>
                <div id='form-services'>
                    <Service number={1} text={pt ? "Melhore a sua presença digital" : "Improve your digital presence"} img={WebImage}  service="Web"/>
                    <Service number={2} text={pt ? "Crie a sua nova aplicação mobile" : "Create your new mobile app"} img={AppImage} service="App"/>
                    <Service number={3} text={pt ? "Desperte a sua marca com um design inovador." : "Level up your brand with innovative design"} img={UxImage} service="UX & UI"/>
                    <Service number={4} text={pt ? "Abrace e descubra o potencial do web3" : "Embrace and discover the potential of web3"} img={Web3Image} service="Blockchain"/>
                    <Service number={5} text={pt ? "Utilize as novas tendências da IA" : "Explore the new AI trends"} img={AIImage} service="AI"/>
                    <Service number={6} text={pt ? "Guiamos o seu sucesso digital" : "We guide your digital success"} img={StartupsImage} service="Startups"/>
                </div>
            </div>
        </div>
    );
}

export default Services;