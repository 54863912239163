import React from "react";

function create() {
  
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;


  return (
    <div id="home-create-all" className="home-section1-sections flex justify-center items-center bg-homeAboutCreate bg-no-repeat bg-cover bg-center w-3/5">
      <div id="home-create-container" className="home-section1-sections-title relative flex">
        <h1 className="relative text-h1">{pt ? "Imaginamos o futuro" : "We image the future"}</h1>
      </div>
    </div>
  );
}

export default create;
